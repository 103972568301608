<!-- SendInvitation.vue -->
<template>
    <v-container>
        <v-data-table :headers="headers" :items="entities" :search="search" :loading="loading" item-key="id">
            <template v-slot:top>
                <!-- <v-row>
                    <v-spacer />
                    <v-btn @click="emailDialog = true">Send Email</v-btn>
                </v-row> -->
                <v-row>
                    <v-col>
                        <v-text-field v-model="search" label="Search" single-line></v-text-field>
                    </v-col>
                    <v-col class="text-right">
                        <!-- <v-btn color="primary" @click="importDialog = true">Import CSV</v-btn> -->
                        <v-btn class="ml-2" color="primary" @click="addItem">Add</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item="{ item }">
                <tr>
                    <td><v-checkbox class="d-inline-flex" v-model="item.selected"></v-checkbox>
                    </td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.entity }}</td>
                    <td>{{ item.firstname }}</td>
                    <td>{{ item.lastname }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.invitations }}</td>
                    <!-- <td>{{ item.slots }}</td> -->
                    <td>
                        <v-btn @click="downloadURLs(item)" color="warning" icon>
                            <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <v-btn @click="editInvitations(item)" color="blue" icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn @click="confirmDelete(item)" color="error" icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>

            </template>
        </v-data-table>
        <v-dialog v-model="dialogInvitations" max-width="500px">
            <v-card>
                <v-card-title>Change invitations</v-card-title>
                <v-card-text>
                    <v-text-field v-model="changeInvitations" label="Invitations" type="Number"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialogInvitations">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveInvitations">Save</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog height="70vh" v-model="dialogSlots" max-width="500px">
            <v-card>
                <v-card-title>Check Slots</v-card-title>
                <v-card-text>
                    <v-select :items="showSlotDates" item-value="id" item-title="name" v-model="showSlotDate"
                        label="Select a Date">
                    </v-select>
                    <v-list>
                        <v-list-item v-for="slot in showSlotTimes[showSlotDate]" v-bind:key="slot">{{ slot }}</v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>

        </v-dialog>

        <v-dialog height="70vh" v-model="importDialog" max-width="500px">
            <v-card>
                <v-card-title>Send Invitations</v-card-title>
                <v-card-text>

                    <v-text-field v-model="invitations" label="Invitations" type="number"></v-text-field>
                    <v-data-table :headers="headersEntities" :items="allEntities" :search="searchEntities"
                        v-model="selectedEntities" :loading="loadingEntities" show-select item-key="id" return-object>
                    </v-data-table>

                </v-card-text>
                <v-card-actions>
                    <!-- <v-spacer></v-spacer>
                    <v-btn color="primary" @click="importCSV">Import CSV</v-btn> -->
                    <v-spacer></v-spacer>
                    <v-btn color="orange darken-1" @click="importDialog = false">Close</v-btn>
                    <v-btn :disabled="entitiesFormValid" color="blue darken-1" @click="addEntities">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="emailDialog" max-width="500px">
            <v-card>
                <v-card-title>Send an email?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="emailDialog = false">Cancel</v-btn>
                    <v-btn color="primary" @click="sendEmail">Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="confirmationDialog" max-width="500px">
            <v-card>
                <v-card-title>Are you sure?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="cancelDelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="deleteItem()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
  
<script>
import { v4 as uuidv4 } from 'uuid';
import { db } from "@/plugins/firebase";
import {
    collection,
    getDocs,
    deleteDoc,
    addDoc,
    doc,
    setDoc,
    getDoc,
    updateDoc,
    writeBatch,
    query,
    where,
    // documentId
} from "@firebase/firestore";
import moment from "moment";
// import { parse } from "papaparse";



export default {
    name: 'SendInvitation',
    props: {
        sectionId: String,
        eventId: String,
        eventList: Array,
        eventSections: Object,
        sectionDates: Object,
        eventInfo: Object,
    },
    watch: {
        eventId: {
            deep: true, immediate: true, handler() { // watch it
                this.fetchEventCollectors();
            }
        }
    },
    components: {
    },
    data() {
        return {
            toDelete: '',
            confirmationDialog: false,
            entityInvitationsItem: {},
            changeInvitations: 0,
            dialogInvitations: false,
            showSlotDate: '',
            showSlotDates: [],
            showSlotTimes: {},
            dialogSlots: false,
            entityIds: [],
            searchEntities: "",
            loadingEntities: false,
            allEntities: [],
            headersEntities: [
                { title: "Type", value: "type" },
                { title: "Entity", value: "entity" },
                // { title: "email", value: "email" },
                // { title: "Action", value: "action", sortable: false },
            ],
            emailDialog: false,
            headerSlots: [
                { title: 'Slot', value: 'slot' },
                // { title: 'Available', value: 'available' },
            ],
            invitations: 1,
            dateSlots: {},
            slotsSelected: [],
            dateSelected: '',
            importDialog: false,
            availableSlots: [],
            selectSlot: "No",
            slotDialog: false,
            entityTypes: ['gallery', 'sponsor'],
            showEntities: false,
            selectedEntities: [],
            entities: [],
            tab: 0,
            events: [],
            headers: [
                { title: "", value: "selected" },
                { title: "Type", value: "type" },
                { title: "Entity", value: "entity" },
                { title: "First Name", value: "firstname" },
                { title: "Last Name", value: "lastname" },
                { title: "email", value: "email" },
                { title: "Invitations", value: "invitations" },
                // { title: "Restricted Slots", value: "restrict" },
                { title: "Slots", value: "slots" },
            ],
            search: "",
            singleSelect: false,
            loading: false,
            selectedItems: [],
            dialog: false,
            selectedDate: "",
            selectedTime: "",
            sectionList: [],
            galleries: [],
            sponsors: [],
            collectors: [],
            entitiesDialog: false,
            editedItem: {
                id: null,
                type: "",
                entity: "",
                firstname: "",
                lastname: "",
                email: "",
                invitations: 1,
                slots: [],
            },
            valid: false,
        };
    },
    computed: {
        entitiesFormValid() {
            return this.selectedEntities.length === 0
        },
        dialogTitle() {
            return this.editedItem.id ? "Edit Item" : "Add Item";
        },
        isValidDateAndTime() {
            return moment(`${this.selectedDate} ${this.selectedTime}`, "YYYY-MM-DD HH:mm", true).isValid();
        },
    },
    methods: {
        async downloadURLs(item) {
            let queryRef = query(
                collection(db, 'rsvp'),
                where('entityId', '==', item.id)
            );
            let querySnap = await getDocs(queryRef);

            let urls = []
            querySnap.forEach((docSnap) => {
                // console.log(`DELETING ${docSnap.ref.id}`);
                const data = docSnap.data()
                urls.push(`https://invitation.curation-fair.com/events?id=${docSnap.ref.id}&t=${data.token}`)
            });

            const textDoc = document.createElement('a');

            textDoc.href = 'data:attachment/text,' + encodeURI(urls.join('\n'));
            textDoc.target = '_blank';
            textDoc.download = `links_${item.entity}.txt`;
            textDoc.click();
        },

        async saveInvitations() {

            const docRefSectionInvitations = doc(collection(db, `invitations/${this.eventId}/partners`), this.entityInvitationsItem.id)
            await updateDoc(docRefSectionInvitations, { invitations: this.changeInvitations });
            // Set number of invitations in the event section summary
            const extraInvitations = this.changeInvitations - this.entityInvitationsItem.invitations
            console.log(this.changeInvitations, this.entityInvitationsItem.invitations, extraInvitations)
            this.addInvitations(this.entityInvitationsItem, this.eventId, extraInvitations)

            this.entities.map((entity, index) => {
                if (entity.id === this.entityInvitationsItem.id) {
                    this.entities[index]['invitations'] = this.changeInvitations;
                }
                return entity
            })
            const collectionRef = doc(collection(db, `invitations/${this.eventId}/partners`), this.entityInvitationsItem.id);
            await updateDoc(collectionRef, { invitations: this.changeInvitations });

            this.closeDialogInvitations()
        },
        closeDialogInvitations() {
            this.dialogInvitations = false;
            this.entityInvitationsItem = {}
            this.changeInvitations = 0
        },
        editInvitations(item) {
            this.dialogInvitations = true;
            this.changeInvitations = item.invitations;
            this.entityInvitationsItem = item;
        },
        showSlots(slots) {
            this.showSlotDates = [];
            this.showSlotTimes = {};
            this.showSlotDate = '';

            for (let slot in slots) {
                this.showSlotDates.push({ id: slot, name: slot.split('_')[2] })
                this.showSlotTimes[slot] = slots[slot].sort()
            }
            this.dialogSlots = true;
        },

        async addItem() {
            this.fetchEntities()
            this.importDialog = true;
        },
        async addEntities() {
            const finalData = this.selectedEntities.map((item) => {
                item.slots = { ...this.slotsSelected };
                item.invitations = this.invitations
                // item.restrict = this.selectSlot;
                return item;
            })
            await this.uploadData(finalData);
        },

        async createEvent(entityId, eventId, sectionId, nInvitations) {
            const mySlots = { ...this.slotsSelected }

            // Upload logos to bucket
            const eventSections = { ...this.eventSections }
            const docRefEvents = doc(collection(db, `events/${entityId}/master`), eventId);
            try {
                // Create the event in the master event section


                const querySnapshot = await getDoc(docRefEvents);
                eventSections[sectionId].invitations = this.invitations;
                if (querySnapshot.exists()) {
                    const data = querySnapshot.data()
                    if (Object.keys(data).includes('sections')) {
                        data.sections[sectionId] = eventSections[sectionId]
                    } else {
                        data.sections = {}
                        data.sections[sectionId] = eventSections[sectionId]
                    }

                    await setDoc(docRefEvents, { sections: data.sections });
                } else {
                    await setDoc(docRefEvents, { sections: { [`${sectionId}`]: eventSections[sectionId] } });
                }
            }
            catch (error) {
                console.error("Error adding sections:", error);
            }
            try {

                await setDoc(docRefEvents, { info: this.eventInfo }, { merge: true });
                // Set number of invitations in the event section summary

            } catch (error) {
                console.error("Error adding info:", error);
            }

            try {
                // sectionMap[`sections.${sectionId}`] = eventSections[sectionId]
                // console.log({[`sections.${sectionId}`]: eventSections[sectionId]})



                // Number of invitations for this section
                const docRefSectionInvitations = doc(collection(db, `events/${entityId}/master/${eventId}/sections/`), sectionId)
                await setDoc(docRefSectionInvitations, { invitations: this.invitations });
            } catch (error) {
                console.error("Error adding invitations:", error);
            }

            try {
                const dates = {}
                const slotIds = Object.keys(mySlots)
                // this.sectionDates containes the original dates
                dates[this.sectionId] = []
                for (let i in slotIds) {
                    const slotId = slotIds[i]
                    const docRefSections = doc(collection(db, `events/${entityId}/master/${eventId}/sections/${sectionId}/slots`), slotId);
                    const slotAvailable = {}
                    dates[this.sectionId].push({
                        id: slotId,
                        name: slotId.split('_')[2]
                    })
                    mySlots[slotId].forEach(slot => {
                        slotAvailable[slot] = nInvitations;
                    })
                    await setDoc(docRefSections, slotAvailable);
                    await updateDoc(docRefEvents, { dates: dates });
                }
            }
            catch (error) {
                console.error("Error adding slots:", error);
            }


        },
        async fetchEntities() {
            // Get collectors that are not added yet
            this.loadingEntities = true;
            try {
                // const querySnapshot = await getDocs(collection(db, `entities/${this.$store.state.org}/collectors`));
                // const q = query(
                //     collection(db, `entities`),
                //     where(documentId(), "not-in",
                //         this.entityIds
                //     ),
                // );
                const querySnapshot = await getDocs(collection(db, `entities`));
                this.allEntities = []
                querySnapshot.docs.forEach((doc) => {
                    if(this.entityIds.indexOf(doc.id) === -1){
                        this.allEntities.push({ id: doc.id, ...doc.data() })
                    }
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loadingEntities = false;
            }
        },
        async addInvitations(entity, eventId, nInvitations) {
            const entityId = entity.id
            const rsvpRef = collection(db, `rsvp`)
            console.log(entity)
            for (let i = 0; i < nInvitations; i++) {
                const token = uuidv4()
                
                const info = {
                    entity: entity.entity,
                    entityId: entityId,
                    eventId: eventId,
                    token: token,
                }
                const docRef = await addDoc(rsvpRef, info)
                await setDoc(doc(collection(db, 'tokens'), docRef.id), {id: docRef.id,  t: token})
            }
        },
        async uploadData(data) {
            try {
                const collectionRef = collection(db, `invitations/${this.eventId}/partners`)
                const batch = writeBatch(db);

                let removeIds = []
                data.forEach((item) => {
                    if (item.id) {
                        removeIds.push(item.email)
                        this.addInvitations(item, this.eventId, item.invitations)
                        const docRef = doc(collectionRef, item.id);
                        console.log(item)
                        delete item.id
                        batch.set(docRef, item);
                    }
                });

                await batch.commit();
                this.allEntities = this.allEntities.filter((entity) => !removeIds.includes(entity.email))


                // remove collectors from the list
                this.selectedEntities = []
                this.fetchEventCollectors();
                this.dateSelected = ''
                // this.slotsSelected[this.dateSelected] = [];


            } catch (error) {
                console.error("Error uploading data:", error);
            }
        },

        async fetchEventCollectors() {
            if (!this.eventId)
                return
            this.loading = true;
            try {
                const querySnapshot = await getDocs(collection(db, `invitations/${this.eventId}/partners/`));
                this.entityIds = ['123']
                this.entities = querySnapshot.docs.map((doc) => {
                    this.entityIds.push(doc.id)
                    return { id: doc.id, ...doc.data() }
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        async fetchSlotDates() {
            this.loading = true;
            try {
                const querySnapshot = await getDocs(collection(db,
                    `events/${this.$store.state.org}/master/${this.eventId}/sections/${this.sectionId}/entities/${this.sectionId}`));
                this.slots = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        cancelDelete(){
            this.toDelete = '';
            this.confirmationDialog = false;
        },
        confirmDelete(item) {
            this.toDelete = item;
            this.confirmationDialog = true;
        },
        async deleteInvitations(entityId) {
            let queryRef = query(
                collection(db, 'rsvp'),
                where('entityId', '==', entityId)
            );
            let querySnap = await getDocs(queryRef);

            let batchRsvp = writeBatch(db);
            let batchTokens = writeBatch(db);
            let batchConfirmed = writeBatch(db);
            let batchPartner = writeBatch(db);

            querySnap.forEach((docSnap) => {
                console.log(`DELETING ${docSnap.ref.id}`);
                batchRsvp.delete(docSnap.ref);
                batchTokens.delete(doc(collection(db, `tokens`), docSnap.ref.id));
                batchConfirmed.delete(doc(collection(db, `confirmed`), docSnap.ref.id));
                batchPartner.delete(doc(collection(db, `invitations/${this.eventId}/partners`), docSnap.ref.id));
            });

            try {
                await batchRsvp.commit();
                await batchTokens.commit();
                await batchConfirmed.commit();
                await batchPartner.commit();
                console.log('Transaction successfully committed!');
            } catch (ex) {
                console.log(`Transaction failed: ${ex.message}`);
                throw ex;
            }
        },
        async deleteItem() {
            this.confirmationDialog = ''
            try {
                await deleteDoc(doc(collection(db,
                    `invitations/${this.eventId}/partners`), this.toDelete.id));

                this.deleteInvitations(this.toDelete.id)

                // Query sections
                // if empty, remove the event
                this.fetchEventCollectors();
                
            } catch (error) {
                console.error("Error deleting item:", error);
            }
            this.confirmationDialog = false;
        },
        sendSelectedEmails() {
            if (this.selectedItems.length > 0) {
                this.dialog = true;
            }
        },
        closeDialog() {
            this.dialog = false;
            this.showEntities = false;
        },
        async sendEmail() {
            // Show the dialog to give the option to select a slot
            this.emailDialog = false;
            // Send the email
            try {
                this.entities.forEach(async item => {
                    if (item.selected) {
                        console.log(item.email)
                        // const qrCodeData = await this.generateQRCode(item.name, dateAndTime);

                        // // Send email with qrCodeData and other details
                        // this.sendEmailWithQRCode(item.email, qrCodeData);

                        // // Update emailSent field in Firestore
                        // await this.updateEmailSentStatus(item.id);
                    }
                })
                // const dateAndTime = `${this.selectedDate} ${this.selectedTime}`;
                // if (!moment(dateAndTime, "YYYY-MM-DD HH:mm", true).isValid()) {
                //     console.error("Invalid date and time");
                //     return;
                // }



                this.emailDialog = false;
            } catch (error) {
                console.error("Error sending email:", error);
            }
        },

        async updateEmailSentStatus(guestId) {
            try {
                await updateDoc(doc(db, "invitations-2024", guestId), {
                    emailSent: true,
                });
                console.log(`Email sent status updated for guest with ID: ${guestId}`);
            } catch (error) {
                console.error("Error updating email sent status:", error);
            }
        },
    },
    mounted() {
    },
};
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  