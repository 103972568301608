<!-- SendInvitation.vue -->
<template>
    <v-container>
        <v-data-table :headers="headers" :items="collectors" :search="search" :loading="loading" item-key="id">
            <template v-slot:top>
                <v-row>
                    <v-col cols="5">
                        <v-text-field readonly v-model="nInvitations" label="Invitations"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field readonly v-model="registered" label="Registered"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field readonly v-model="rsvp0" label="RSVP Exhibition"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field readonly v-model="rsvp1" label="RSVP Art Fair"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="search" label="Search" single-line></v-text-field>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn color="primary" @click="downloadURLs">Download URLs</v-btn>
                        <v-btn :disabled="invitationsLeft === 0" class="ml-2" color="primary" @click="addItem">Add</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item="{ item }">
                <tr>
                    <td><v-checkbox class="d-inline-flex" v-model="item.selected"></v-checkbox>
                    </td>
                    <td>{{ item.firstname }}</td>
                    <td>{{ item.lastname }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.day0 ? item.day0.split('_')[2] : '--' }}</td>
                    <td>{{ item.time0 ? item.time0.split('_')[0] : '--' }}</td>
                    <td>{{ item.day1 ? item.day1.split('_')[2] : '--' }}</td>
                    <td>{{ item.time1 ? item.time1.split('_')[0] : '--' }}</td>
                    <td>{{ item.newsletter ? 'Yes' : 'No' }}</td>

                    <td>
                        <v-btn :disabled="item.sent" @click="showURL(item)" color="primary" icon>
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </td>
                    <td>
                        <v-btn :disabled="item.sent" @click="confirmDelete(item)" color="error" icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>

            </template>
        </v-data-table>
        <v-dialog v-model="collectorDialog" max-width="500px">
            <v-card>
                <v-card-title>Select Collectors</v-card-title>
                <v-card-text>
                    <v-data-table :headers="headersCollectors" :items="allCollectors" :search="searchCollectors"
                        v-model="selectedCollectors" :loading="loadingCollectors" show-select item-key="id" return-object>
                    </v-data-table>

                    <div v-if="invitationsLeft < this.selectedCollectors.length">{{ `You only have ${invitationsLeft}
                        invitations left`}}</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange darken-1" @click="collectorDialog = false">Close</v-btn>
                    <v-btn :disabled="collectorsFormValid" color="blue darken-1" @click="addCollectors">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="emailDialog" max-width="500px">
            <v-card>
                <v-card-title>Send an email?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="emailDialog = false">Cancel</v-btn>
                    <v-btn color="primary" @click="sendEmail">Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="urlDialog" max-width="500px">
            <v-card>
                <v-card-text>
                    <a :href="urlDialogText" target="_blank">{{ urlDialogText }}</a>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="urlDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>
        <v-dialog v-model="confirmationDialog" max-width="500px">
            <v-card>
                <v-card-title>Are you sure?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="cancelDelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="deleteItem()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </v-container>
</template>
  
<script>
// import { watch } from "vue";
import { v4 as uuidv4 } from 'uuid';
import { db } from "@/plugins/firebase";
import {
    collection,
    getDocs,
    deleteDoc,
    addDoc,
    doc,
    setDoc,
    // getDoc,
    // updateDoc,
    writeBatch,
    query,
    where,
    // documentId,
    and
} from "@firebase/firestore";
import moment from "moment";
// import { parse } from "papaparse";



export default {
    name: 'InvitationsCollector',
    props: {
        sectionId: String,
        eventId: String,
        eventList: Array,
        eventSections: Object,
        sectionDates: Object,
        eventInfo: Object,
    },
    components: {
    },
    watch: {
        eventId: {
            deep: true, immediate: true, handler() { // watch it
                // this.fetchSlotDates()
                this.fetchEventCollectors();
            }
        }
    },
    // setup() {
    //     watch(() => this.props.sectionId, () => { 
    //         // this.fetchSlotDates()
    //         // this.fetchEventCollectors();
    //         console.log('wather')
    //     })
    // },
    data() {
        return {
            confirmationDialog: false,
            toDelete: '',
            urlDialog: false,
            savingData: false,
            slotEdit: {},
            dialogSlots: false,
            restrictSlot: false,
            slotDates: [],
            slotDate: '',
            slotTimes: {},
            slotTime: '',
            collectorIds: ['123'],
            selectedCollectors: [],
            headersCollectors: [
                { title: "First Name", value: "firstname" },
                { title: "Last Name", value: "lastname" },
                // { title: "email", value: "email" },
                // { title: "Action", value: "action", sortable: false },
            ],
            allCollectors: [],
            searchCollectors: "",
            loadingCollectors: false,
            editedCollector: {
                id: null,
                firstname: "",
                lastname: "",
                email: "",
            },
            emailDialog: false,
            headerSlots: [
                { title: 'Slot', value: 'slot' },
                { title: 'Available', value: 'available' },
            ],
            invitations: 1,
            dateSlots: {},
            slotsSelected: [],
            dateSelected: '',
            importDialog: false,
            availableSlots: [],
            selectSlot: "No",
            slotDialog: false,
            entityTypes: ['gallery', 'sponsor'],
            showEntities: false,
            selectedEntities: [],
            entities: [],
            tab: 0,
            events: [],
            headers: [
                { title: "", value: "selected" },
                { title: "First Name", value: "firstname" },
                { title: "Last Name", value: "lastname" },
                { title: "email", value: "email" },
                { title: "Exhibition Day", value: "day0" },
                { title: "Exhibition Slot", value: "time0" },
                { title: "Fair Day", value: "day1" },
                { title: "Fair Slot", value: "time1" },
                { title: "Registered", value: "rsvp" },
                { title: '', value: 'actions'}
            ],
            search: "",
            singleSelect: false,
            loading: false,
            selectedItems: [],
            collectorDialog: false,
            selectedDate: "",
            selectedTime: "",
            sectionList: [],
            galleries: [],
            sponsors: [],
            collectors: [],
            entitiesDialog: false,
            editedItem: {
                id: null,
                firstname: "",
                lastname: "",
                email: "",
                slots: [],
            },
            valid: false,
        };
    },

    computed: {
        nInvitations(){
            return this.collectors.length
        },
        registered(){
            return this.collectors.filter((invitation) => invitation.rsvp).length
        },
        rsvp0(){
            return this.collectors.filter((invitation) => invitation.day0).length
        },
        rsvp1(){
            return this.collectors.filter((invitation) => invitation.day1).length
        },
        sectionsAsList() {
            const sectionList = []
            if (this.eventSelected) {
                for (const [key, value] of Object.entries(this.eventSections[this.eventSelected])) {
                    sectionList.push({ id: key, invitations: value.invitations, name: value.name })
                }
            }
            return sectionList

        },
        collectorsFormValid() {
            //     return this.selectedCollectors.length === 0 || (this.restrictSlot && (this.slotDate == '' || this.slotTime == '')) || this.invitationsLeft < this.selectedCollectors.length
            return this.selectedCollectors.length === 0
        },
        editSlotValid() {
            return (this.restrictSlot && (this.slotDate == '' || this.slotTime == '')) || this.savingData
        },
        invitationsLeft() {
            // const section = this.eventSections.find(section => section.id == this.sectionId)
            // const totalInvitations = this.eventSections[this.sectionId].invitations - this.collectors.length
            // return totalInvitations ? totalInvitations : 0
            return -1
        },
        dialogTitle() {
            return this.editedItem.id ? "Edit Collector" : "Add Collector";
        },
        isValidDateAndTime() {
            return moment(`${this.selectedDate} ${this.selectedTime}`, "YYYY-MM-DD HH:mm", true).isValid();
        },
    },
    methods: {
        showURL(collector) {
            this.urlDialog = true
            this.urlDialogText = `https://invitation.curation-fair.com/events?id=${collector.invite}&t=${collector.token}`
        },
        downloadURLs() {
            const urls = ['firstname,lastname,email,url,Exhibition Day,Exhibition Time,Fair Day,Fair Time,Registered']
            const selection = this.collectors.filter((collector) => collector.selected)
            if (selection.length > 0) {
                selection.forEach((collector) => {
                    const url = `https://invitation.curation-fair.com/events?id=${collector.invite}&t=${collector.token}`
                    urls.push(`${collector.firstname},${collector.lastname},${collector.email},${url},${collector.day0 ? collector.day0.split('_')[2] : ''},${collector.time0 ? collector.time0.split('_')[0] : ''},${collector.day1 ? collector.day1.split('_')[2] : ''},${collector.time1 ? collector.time1.split('_')[0] : ''},${collector.rsvp ? 'Yes' : 'No' }`)
                })
            } else {
                this.collectors.forEach((collector) => {
                    const url = `https://invitation.curation-fair.com/register?id=${collector.invite}&t=${collector.token}`
                    urls.push(`${collector.firstname},${collector.lastname},${collector.email},${url},${collector.day0 ? collector.day0.split('_')[2] : ''},${collector.time0 ? collector.time0.split('_')[0] : ''},${collector.day1 ? collector.day1.split('_')[2] : ''},${collector.time1 ? collector.time1.split('_')[0] : ''},${collector.rsvp ? 'Yes' : 'No' }`)
                })
            }

            const textDoc = document.createElement('a');

            textDoc.href = 'data:attachment/text,' + encodeURI(urls.join('\n'));
            textDoc.target = '_blank';
            textDoc.download = `links.csv`;
            textDoc.click();
        },
        editSlot(item) {
            this.dialogSlots = true
            this.slotEdit = { id: item.id, restricted: item.restricted, date: item.date, time: item.time }
        },
        closeEditSlot() {
            this.dialogSlots = false
        },

        async addCollectors() {
            this.loadingCollectors = true
            const collectionRef = collection(db, `events/${this.$store.state.org}/master/${this.eventId}/collectors`)
            // const batch = writeBatch(db);
            let removeIds = []
            const rsvpRef = collection(db, `rsvp`)
            // const collectorInviteMap =  this.selectedCollectors.map(async (collector) => {
            //     const info = {
            //         entityId: this.$store.state.org,
            //         eventId: this.eventId,
            //         token: uuidv4()
            //     }
            //     const inviteRef = await addDoc(rsvpRef, info)
            //     collectorInviteMap[collector.id] = inviteRef.id
            //     console.log(collector.id, inviteRef.id)
            //     return inviteRef.id
            // });
            // console.log('array ', collectorInviteMap)
            // this.selectedCollectors.forEach(async (collector) => {
            for (let c = 0; c < this.selectedCollectors.length; c++) {
                const token = uuidv4()

                const collector = this.selectedCollectors[c];
                const info = {
                    id: collector.id,
                    entity: 'UAN',
                    entityId: this.$store.state.org,
                    eventId: this.eventId,
                    token: token,
                    firstname: collector.firstname,
                    lastname: collector.lastname,
                    email: collector.email,
                }
                const inviteRef = await addDoc(rsvpRef, info)
                await setDoc(doc(collection(db, 'tokens'), inviteRef.id), { id: inviteRef.id, t: token })


                removeIds.push(collector.id)
                this.collectorIds.push(collector.id)
                const docRef = doc(collectionRef, collector.id);
                const item = {
                    invite: inviteRef.id,
                    firstname: collector.firstname,
                    lastname: collector.lastname,
                    email: collector.email,
                    token: token,
                    eventId: this.eventId,
                }
                this.collectors.push({ id: collector.id, ...item })
                await setDoc(docRef, item)
                // batch.set(docRef, item);


            }

            // await batch.commit();
            this.allCollectors = this.allCollectors.filter((collector) => !removeIds.includes(collector.id))
            // remove collectors from the list
            this.selectedCollectors = []
            this.loadingCollectors = false;


        },

        async fetchCollectors() {
            // Get collectors that are not added yet
            this.loadingCollectors = true;
            try {
                // const querySnapshot = await getDocs(collection(db, `entities/${this.$store.state.org}/collectors`));
                // const q = query(
                //     collection(db, `entities/${this.$store.state.org}/collectors`),
                //     where(documentId(), "not-in",
                //         this.collectorIds
                //     ),
                // );
                const querySnapshot = await getDocs(collection(db, `entities/${this.$store.state.org}/collectors`));
                this.allCollectors = []
                querySnapshot.docs.forEach((doc) => {
                    if(this.collectorIds.indexOf(doc.id) === -1){
                        this.allCollectors.push({ id: doc.id, ...doc.data() })
                    }
                });
                // const querySnapshot = await getDocs(q);

                // this.allCollectors = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loadingCollectors = false;
            }
        },
        addItem() {
            this.fetchCollectors()
            this.collectorDialog = true;
        },
        editItem(item) {
            this.editedItem = { ...item };
            this.valid = true;
            this.dialog = true;
        },


        async uploadData(data) {
            try {
                const collectionRef = collection(db, `events/${this.$store.state.org}/master/${this.eventId}/collectors`)
                const batch = writeBatch(db);

                data.forEach((item) => {

                    if (item.id) {
                        const docRef = doc(collectionRef, item.id);
                        delete item.id
                        batch.set(docRef, item);
                        // Replicate the event for each entity using their ID
                        // Copy only selected sections/dates/slots


                    }
                });

                await batch.commit();
                this.fetchEventCollectors();


            } catch (error) {
                console.error("Error uploading data:", error);
            }
        },

        async fetchEventCollectors() {
            if (!this.eventId)
                return
            this.loading = true;
            try {
                // const querySnapshot = await getDocs(collection(db, `events/${this.$store.state.org}/master/${this.eventId}/collectors`));
                const querySnapshot = await getDocs(query(collection(db, 'rsvp'), and(where('entityId', '==', this.$store.state.org), where('eventId', '==', this.eventId))))
                this.collectorIds = ['123']
                // this.collectors = []
                this.collectors = querySnapshot.docs.map((doc) => {
                        const data = {invite: doc.id, ...doc.data()}
                        if (data.id)
                            this.collectorIds.push(data.id);
                        // this.collectors.push({ id: doc.id, ...doc.data() })
                        return data
                    }
                );
                // let guests = {}
                // querySnapshot.docs.forEach((doc) => {
                //     guests[doc.id] = doc.data()
                // })



            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        async deleteAll() {
            let queryRef = query(
                collection(db, 'rsvp'),
                where('entityId', '==', this.$store.state.org)
            );
            let querySnap = await getDocs(queryRef);

            let batchRsvp = writeBatch(db);
            let batchTokens = writeBatch(db);
            let batchConfirmed = writeBatch(db);
            // let batchEvent = writeBatch(db);

            querySnap.forEach((docSnap) => {
                console.log(`DELETING ${docSnap.ref.id}`);
                batchRsvp.delete(docSnap.ref);
                batchTokens.delete(doc(collection(db, `tokens`), docSnap.ref.id));
                batchConfirmed.delete(doc(collection(db, `confirmed`), docSnap.ref.id));
                // batchEvent.delete(doc(collection(db,`events/${this.$store.state.org}/master/${this.eventId}/collectors`), docSnap.ref.id));
            });

            try {
                await batchRsvp.commit();
                await batchTokens.commit();
                await batchConfirmed.commit();
                console.log('Transaction successfully committed!');
            } catch (ex) {
                console.log(`Transaction failed: ${ex.message}`);
                throw ex;
            }
        },
        cancelDelete(){
            this.toDelete = '';
            this.confirmationDialog = false;
        },
        confirmDelete(item) {
            this.toDelete = item;
            this.confirmationDialog = true;
        },
        async deleteItem() {
            this.loading = true;

            try {
                await deleteDoc(doc(db, `rsvp/${this.toDelete.invite}`));
                await deleteDoc(doc(db, `confirmed/${this.toDelete.invite}`));
                await deleteDoc(doc(db, `tokens/${this.toDelete.invite}`));
                await deleteDoc(doc(collection(db,
                    `events/${this.$store.state.org}/master/${this.toDelete.eventId}/collectors`), this.toDelete.id));
                


                // Query sections
                // if empty, remove the event
                this.fetchEventCollectors();
            } catch (error) {
                console.error("Error deleting item:", error);
            }
            this.toDelete = '';
            this.loading = false;
            this.confirmationDialog = false;
        },
        sendSelectedEmails() {
            if (this.selectedItems.length > 0) {
                this.dialog = true;
            }
        },
        closeDialog() {
            this.dialog = false;
            this.showEntities = false;
        },

    },
    mounted() {
        // Get available dates and times
        // this.fetchSlotDates()
        // this.fetchEventCollectors();
    },
};
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  