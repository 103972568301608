<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select :items="eventList" item-value="id" item-title="name" label="Select Event" v-model="eventSelected"
            @update:model-value="fetchEventCollectors">
          </v-select>
        </v-col>
        <v-col>
          <v-btn v-if="invitations != ''" class="ml-2" color="primary">
            <download-csv :data="invitations" name="registered_guests.csv">
              Export CSV

              <v-icon end icon="mdi-download"></v-icon>
            </download-csv>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-text-field class="mr-2 ml-2" readonly label="Registered" v-model="invitations.length"></v-text-field>
        <v-text-field class="mr-2" readonly label="Exhibition RSVP" v-model="event0Rsvp"></v-text-field>
        <v-text-field class="mr-2" readonly label="Art Fair RSVP" v-model="event1Rsvp"></v-text-field>
      </v-row>
      <v-row v-if="eventSelected">
        <v-data-table :items="invitations" :search="searchInvitations" :loading="loading" item-key="id" return-object>
          <template v-slot:top>
            <v-row class="ml-2">
              <v-col cols="4">
                <v-text-field v-model="searchInvitations" label="Search" single-line></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
        </v-data-table>
      </v-row>
    </v-card-text>
    <div v-if="dataReady">
      <div class="chart-div">
        <Line :data="lineData" :options="lineOptions" />
      </div>

      <!-- Art Fair -->
      <h2 class="text-center"> Art Fair </h2>

      <div class="chart-div">
        <Bar id="my-chart-id" :options="chartFairOptions" :data="chartFair" />
      </div>

      <div class="chart-div">
        <Bar id="my-chart-id" :options="chartFairAttendOptions" :data="chartFairAttend" />
      </div>

      <v-divider></v-divider>
      <!-- Exhibition -->
      <h2 class="text-center"> Exhibition </h2>
      <div class="chart-div">
        <Bar id="my-chart-id" :options="chartExhibitionOptions" :data="chartExhibition" />
      </div>

      <div class="chart-div">
        <Bar id="my-chart-id" :options="chartAttendOptions" :data="chartAttend" />
      </div>
    </div>
  </v-card>
</template>

<script>

// import { auth, db } from '@/plugins/firebase'
// import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
// import { doc, setDoc } from "firebase/firestore";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { db } from "@/plugins/firebase";
import {
  collection,
  getDocs,
  query,
  orderBy
} from "@firebase/firestore";
import { Bar, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LineElement, PointElement, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)


export default {
  name: 'MgmtDashboard',
  props: {
    invitationId: String
  },
  components: {
    Bar,
    Line
  },
  computed: {
    event0Rsvp() {
      return this.invitations.filter((invitation) => invitation.day_exhib !== '').length
    },
    event1Rsvp() {
      return this.invitations.filter((invitation) => invitation.day_fair !== '').length
    },
  },
  data() {
    return {
      lineOptionsB: {
        responsive: true,
        maintainAspectRatio: false
      },
      lineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y1: {
            type: 'linear',
            position: 'left',
          },
          y2: {
            type: 'linear',
            position: 'right',
          }
        }
      },
      lineData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ],
      },
      dataReady: false,
      chartExhibitionOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Reservations per slot'
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      },
      chartFairOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Reservations per slot'
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      },
      chartFairAttendOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Guest Attendance'
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      },
      chartAttendOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Guest Attendance'
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      },
      headers: [
        { title: 'Entity', value: 'entity' },
        { title: 'Exhibition day', value: 'day0' },
        { title: 'Exhibition time', value: 'time0' },
        { title: 'Fair day', value: 'day1' },
        { title: 'Fair time', value: 'time1' },
      ],
      sortBy: { title: 'Entity', value: 'entity' },
      searchInvitations: '',
      email: '',
      password1: '',
      password2: '',
      firstname: '',
      lastname: '',
      invitationid: '',
      validated: true,
      loadiong: false,
      events: [],
      eventList: [],
      eventSelected: '',
      eventInfo: {},
      sectionDates: {},
      invitations: []
    }
  },
  methods: {
    downloadURLs() {
      const urls = ['firstname,lastname,email,url,Exhibition Day,Exhibition Time,Fair Day,Fair Time,Registered']
      const selection = this.collectors.filter((collector) => collector.selected)
      if (selection.length > 0) {
        selection.forEach((collector) => {
          const url = `https://invitation.curation-fair.com/events?id=${collector.invite}&t=${collector.token}`
          urls.push(`${collector.firstname},${collector.lastname},${collector.email},${url},${collector.day0 ? collector.day0.split('_')[2] : ''},${collector.time0 ? collector.time0.split('_')[0] : ''},${collector.day1 ? collector.day1.split('_')[2] : ''},${collector.time1 ? collector.time1.split('_')[0] : ''},${collector.rsvp ? 'Yes' : 'No'}`)
        })
      } else {
        this.collectors.forEach((collector) => {
          const url = `https://invitation.curation-fair.com/register?id=${collector.invite}&t=${collector.token}`
          urls.push(`${collector.firstname},${collector.lastname},${collector.email},${url},${collector.day0 ? collector.day0.split('_')[2] : ''},${collector.time0 ? collector.time0.split('_')[0] : ''},${collector.day1 ? collector.day1.split('_')[2] : ''},${collector.time1 ? collector.time1.split('_')[0] : ''},${collector.rsvp ? 'Yes' : 'No'}`)
        })
      }

      const textDoc = document.createElement('a');

      textDoc.href = 'data:attachment/text,' + encodeURI(urls.join('\n'));
      textDoc.target = '_blank';
      textDoc.download = `links.csv`;
      textDoc.click();
    },
    async fetchEventCollectors() {
      if (!this.eventSelected)
        return
      this.loading = true;
      try {
        // const querySnapshot = await getDocs(collection(db, `events/${this.$store.state.org}/master/${this.eventId}/collectors`));
        // const querySnapshot = await getDocs(query(collection(db, 'rsvp'), and(where('rsvp', '==', true), where('eventId', '==', this.eventSelected)), orderBy('ts0', 'desc')))
        const querySnapshot = await getDocs(query(collection(db, 'rsvp'), orderBy('ts', 'desc')))
        // this.collectors = []
        let timeSlotsExhibition = {}
        let timeSlotsFair = {}
        // let timesExhibition = {}
        // let timesFair = {}
        let invDateCounter = {}
        const hoursExhib = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00']
        const daysExhib = ['2024-2-21', '2024-2-22', '2024-2-23', '2024-2-24', '2024-2-25', '2024-2-26', '2024-2-27', '2024-2-28', '2024-2-29', '2024-3-1', '2024-3-2', '2024-3-3']
        const hoursFair = ['11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'];
        const daysFair = ['2024-3-8', '2024-3-9', '2024-3-10', '2024-3-11']
        // Get scanned invitations
        const logRef = collection(db, `log`);
        
        const logDocs = await getDocs(logRef);
        const receivedGuests = {}
        const bakLog = {}
        if (logDocs.size > 0) {
          logDocs.docs.forEach((log) => {
            
            const data = log.data()
            bakLog[data.token] = data
            const lastEvent = data.events[data.events.length - 1]
            receivedGuests[data.token] = { sid: data.sid, msg: lastEvent.msg, ts: `${(new Date(Number(lastEvent.ts) * 1000)).toLocaleString()}` }
          
          })
          console.log(bakLog)
        }

        const attendanceData = {}

        for (let d = 0; d < daysExhib.length; d++) {
          attendanceData[daysExhib[d]] = { rsvp: 0, attended: 0 }
        }

        const attendanceFairData = {}

        for (let d = 0; d < daysFair.length; d++) {
          attendanceFairData[daysFair[d]] = { rsvp: 0, attended: 0 }
        }

        this.invitations = querySnapshot.docs.map((doc) => {
          const inv = doc.data()
          const invDate = inv.ts.toDate().toLocaleDateString('ja-JP');
          const exhibitionRegDatetime = inv.ts0 ? inv.ts0.toDate().toLocaleString('ja-JP') : '';
          const fairRegDatetime = inv.ts1 ? inv.ts1.toDate().toLocaleString('ja-JP') : '';
          if (invDate in invDateCounter) {
            invDateCounter[invDate] += 1
          } else {
            invDateCounter[invDate] = 1
          }
          const data = {
            // invite: doc.id,
            entity: inv.entity,
            firstname: inv.firstname,
            lastname: inv.lastname,
            email: inv.email,
            day_exhib: inv.day0 ? inv.day0.split('_')[2] : '',
            time_exhib: inv.time0 ? inv.time0.replace('_', '~') : '',
            Exhibition_scanned: receivedGuests[inv.token] && receivedGuests[inv.token].sid == 0 ? receivedGuests[inv.token].ts : '',
            day_fair: inv.day1 ? inv.day1.split('_')[2] : '',
            time_fair: inv.time1 ? inv.time1.replace('_', '~') : '',
            Fair_scanned: receivedGuests[inv.token] && receivedGuests[inv.token].sid == 1 ? receivedGuests[inv.token].ts : '',
            plusone_exhib: inv.plusone0 ? 'Yes' : 'No',
            plusone_fair: inv.plusone1 ? 'Yes' : 'No',
            Registration: invDate,
            rsvp_exhibition: exhibitionRegDatetime,
            rsvp_fair: fairRegDatetime,
            token: inv.token
            // rsvp: inv.rsvp
          }
          if (data.day_exhib !== '') {
            // if (data.Registration)
            //   attendanceData[data.day_exhib]['registered'] += 1
            if (data.Exhibition_scanned)
              attendanceData[data.day_exhib]['attended'] += 1
            else if (data.day_exhib)
              attendanceData[data.day_exhib]['rsvp'] += 1

          }

          if (data.day_fair !== '') {
            // if (data.Registration)
            //   attendanceData[data.day_exhib]['registered'] += 1
            if (data.Fair_scanned)
              attendanceFairData[data.day_fair]['attended'] += 1
            else if (data.day_fair)
            attendanceFairData[data.day_fair]['rsvp'] += 1

          }


          let time_exhib = data.time_exhib.split('~')[0]
          let time_fair = data.time_fair.split('~')[0]

          if (data.day_exhib !== '') {
            if (data.day_exhib in timeSlotsExhibition) {
              if (time_exhib in timeSlotsExhibition[data.day_exhib]) {
                timeSlotsExhibition[data.day_exhib][time_exhib] += data.plusone_exhib ? 2 : 1;
              } else {
                timeSlotsExhibition[data.day_exhib][time_exhib] = data.plusone_exhib ? 2 : 1
              }
            } else {
              timeSlotsExhibition[data.day_exhib] = {}
              timeSlotsExhibition[data.day_exhib][time_exhib] = data.plusone_exhib ? 2 : 1
            }

          }
          // if (time_exhib in timesExhibition) {
          //   timesExhibition[time_exhib] += data.plusone_exhib ? 2 : 1
          // } else {
          //   timesExhibition[time_exhib] = data.plusone_exhib ? 2 : 1
          // }

          if (data.day_fair !== '') {
            if (data.day_fair in timeSlotsFair) {
              if (time_fair in timeSlotsFair[data.day_fair]) {
                timeSlotsFair[data.day_fair][time_fair] += data.plusone_fair ? 2 : 1;
              } else {
                timeSlotsFair[data.day_fair][time_fair] = data.plusone_fair ? 2 : 1
              }
            } else {
              timeSlotsFair[data.day_fair] = {}
              timeSlotsFair[data.day_fair][time_fair] = data.plusone_fair ? 2 : 1
            }
          }
          // if (time_fair in timesFair) {
          //   timesFair[time_fair] += data.plusone_exhib ? 2 : 1
          // } else {
          //   timesFair[time_fair] = data.plusone_exhib ? 2 : 1
          // }

          // this.collectors.push({ id: doc.id, ...doc.data() })
          return data
        });


        const lineLabels = Object.keys(invDateCounter)
        lineLabels.sort(function (a, b) {
          a = new Date(a);
          b = new Date(b);
          return a - b;//a >b ? -1 : a < b ? 1 : 0;
        })
        const lineData = []
        const lineDataCummulative = []
        let runningTotal = 0
        for (let d = 0; d < lineLabels.length; d++) {
          const regDate = lineLabels[d]
          lineData.push(invDateCounter[regDate])
          runningTotal += invDateCounter[regDate]
          lineDataCummulative.push(runningTotal)
        }
        this.lineData = {
          labels: lineLabels,
          datasets: [
            {
              type: 'bar',
              yAxisID: 'y2',
              label: 'Registrations per day',
              backgroundColor: '#f87979',
              data: lineData
            },
            {
              type: 'line',
              yAxisID: 'y1',
              label: 'Cummulative registrations',
              backgroundColor: '#0bb4ff',
              data: lineDataCummulative
            }
          ],
        };

        const exhibColours = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0", "#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78"]
        const fairColours = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0", "#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78"]




        const slotsPerDayExhibition = {}
        // for (let time in timesExhibition) {
        //   for (let day in timeSlotsExhibition) {

        for (let t = 0; t < hoursExhib.length; t++) {
          const time = hoursExhib[t]
          for (let d = 0; d < daysExhib.length; d++) {
            const day = daysExhib[d]
            if (!(time in slotsPerDayExhibition))
              slotsPerDayExhibition[time] = {}
            if (day in timeSlotsExhibition) {
              slotsPerDayExhibition[time][day] = time in timeSlotsExhibition[day] ? timeSlotsExhibition[day][time] : 0
            } else {
              slotsPerDayExhibition[time][day] = 0
            }

          }
        }

        const slotsPerDayFair = {}
        
        for (let t = 0; t < hoursFair.length; t++) {
          const time = hoursFair[t]
          for (let d = 0; d < daysFair.length; d++) {
            const day = daysFair[d]
            if (!(time in slotsPerDayFair))
              slotsPerDayFair[time] = {}
            if (day in timeSlotsFair)
              slotsPerDayFair[time][day] = time in timeSlotsFair[day] ? timeSlotsFair[day][time] : 0
          }
        }

        this.chartExhibition = {
          labels: daysExhib,
          datasets: []
        }

        this.chartFair = {
          labels: daysFair,
          datasets: []
        }

        this.chartAttend = {
          labels: daysExhib,
          datasets: []
        }

        this.chartFairAttend = {
          labels: daysFair,
          datasets: []
        }

        const registeredValues = []
        const attendedValues = []
        for (let d = 0; d < daysExhib.length; d++) {
          registeredValues.push(attendanceData[daysExhib[d]].rsvp)
          attendedValues.push(attendanceData[daysExhib[d]].attended)
        }


        this.chartAttend.datasets.push({
          label: 'Attended',
          backgroundColor: "#0bb4ff",
          data: attendedValues
        })

        this.chartAttend.datasets.push({
          label: 'RSVP',
          backgroundColor: "#e60049",
          data: registeredValues
        })

        const registeredFairValues = []
        const attendedFairValues = []
        for (let d = 0; d < daysFair.length; d++) {
          registeredFairValues.push(attendanceFairData[daysFair[d]].rsvp)
          attendedFairValues.push(attendanceFairData[daysFair[d]].attended)
        }


        this.chartFairAttend.datasets.push({
          label: 'Attended',
          backgroundColor: "#0bb4ff",
          data: attendedFairValues
        })

        this.chartFairAttend.datasets.push({
          label: 'RSVP',
          backgroundColor: "#e60049",
          data: registeredFairValues
        })

        for (let t = 0; t < hoursExhib.length; t++) {
          const time = hoursExhib[t]
          if (time in slotsPerDayExhibition)
            this.chartExhibition.datasets.push({
              label: time,
              backgroundColor: exhibColours.shift(),
              data: Object.values(slotsPerDayExhibition[time])
            })
        }

        for (let t = 0; t < hoursFair.length; t++) {
          const time = hoursFair[t]
          if (time in slotsPerDayFair)
            this.chartFair.datasets.push({
              label: time,
              backgroundColor: fairColours.shift(),
              data: Object.values(slotsPerDayFair[time])
            })
        }

        this.dataReady = true

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchEventList() {

      this.loading = true;
      // Get it from the organiser entity id.
      // This needs to be all the info
      try {
        const querySnapshot = await getDocs(collection(db, `events/${this.$store.state.org}/master/`));
        this.events = []//querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        this.eventList = []//querySnapshot.docs.map(doc => doc.data().info.eventName);
        this.eventSections = {}

        querySnapshot.docs.forEach(doc => {
          const data = doc.data()
          this.eventList.push({ id: doc.id, name: data.info.eventName })
          this.sectionDates[doc.id] = data.dates
          this.eventInfo[doc.id] = data.info
        })
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.invitationid = this.invitationId;
    this.fetchEventList();

    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          result;
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // this.$store.commit('login', result.user)
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.log(error)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  },
}
</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: black;
}

* {
  box-sizing: border-box;
}

.chart-div {
  min-height: 350px;
  padding: 25px;
}

/* Add padding to containers */
.container {
  padding: 16px;
  background-color: white;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

/* Full-width input fields */
input[type=text],
input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus,
input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.registerbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.registerbtn:hover {
  opacity: 1;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
</style>
