<!-- SendInvitation.vue -->
<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="6">
                        <v-select :items="eventList" item-value="id" item-title="name" label="Select Event"
                            v-model="eventSelected" @update:model-value="updateEventData">
                        </v-select>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-tabs v-model="tab" bg-color="primary">
                <v-tab v-if="$store.state.admin === true" value="entities">Entities</v-tab>
                <v-tab value="collectors">Collectors</v-tab>
            </v-tabs>

            <v-card-text>
                <v-window v-model="tab">
                    <v-window-item v-if="$store.state.admin === true" value="entities">
                        <InvitationsEntity :sectionId="sectionSelected" :eventId="eventSelected" :eventList="eventList"
                            :eventSections="eventSections[eventSelected]" :sectionDates="sectionDates[eventSelected]"
                            :eventInfo="eventInfo[eventSelected]" />
                    </v-window-item>
                    <v-window-item value="collectors">
                        <InvitationsCollector :sectionId="sectionSelected" :eventId="eventSelected" :eventList="eventList"
                            :eventSections="eventSections[eventSelected]" :sectionDates="sectionDates[eventSelected]"
                            :eventInfo="eventInfo[eventSelected]" />
                    </v-window-item>

                </v-window>

            </v-card-text>
        </v-card>
    </v-container>
</template>
  
<script>
import { db } from "@/plugins/firebase";
import {
    collection,
    getDocs,
} from "@firebase/firestore";
import moment from "moment";
import InvitationsEntity from "@/components/InvitationsEntitySimple.vue";
import InvitationsCollector from "@/components/InvitationsCollectorSimple.vue";



export default {
    name: 'SendInvitation',
    components: {
        InvitationsEntity,
        InvitationsCollector
    },
    data() {
        return {
            eventInfo: {},
            sectionSelected: '',
            eventSelected: '',
            eventList: [],
            eventSections: {},
            sectionDates: {},
            eventData: {},
            nInvitations: 0,


            dateSlots: {},
            slotsSelected: [],
            dateSelected: '',
            importDialog: false,
            availableSlots: [],
            selectSlot: "No",
            slotDialog: false,
            entityTypes: ['gallery', 'sponsor'],
            showEntities: false,
            selectedEntities: [],
            entities: [],
            tab: 0,
            events: [],
        };
    },
    computed: {
        sectionsAsList() {
            const sectionList = []
            if (this.eventSelected) {
                for (const [key, value] of Object.entries(this.eventSections[this.eventSelected])) {
                    sectionList.push({ id: key, invitations: value.invitations, name: value.name })
                }
            }
            return sectionList

        },
        dialogTitle() {
            return this.editedItem.id ? "Edit Item" : "Add Item";
        },
        isValidDateAndTime() {
            return moment(`${this.selectedDate} ${this.selectedTime}`, "YYYY-MM-DD HH:mm", true).isValid();
        },
    },
    methods: {
        updateEventData() {
            this.sectionSelected = ''
            // this.eventData = this.eventData[this.eventSelected]
        },
        async fetchEventList() {

            this.loading = true;
            // Get it from the organiser entity id.
            // This needs to be all the info
            try {
                const querySnapshot = await getDocs(collection(db, `events/${this.$store.state.org}/master/`));
                this.events = []//querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                this.eventList = []//querySnapshot.docs.map(doc => doc.data().info.eventName);
                this.eventSections = {}

                querySnapshot.docs.forEach(doc => {
                    const data = doc.data()
                    this.eventList.push({ id: doc.id, name: data.info.eventName })
                    this.sectionDates[doc.id] = data.dates
                    this.eventSections[doc.id] = data.sections//.filter((item) => item.invitations > 0)
                    this.eventInfo[doc.id] = data.info
                })
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchEventList();
    },
};
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  