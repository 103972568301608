<!-- FirestoreTable.vue -->
<template>
    <v-container>
        <v-data-table :headers="headers" :items="items" :search="search" :single-select="singleSelect" :loading="loading"
            item-key="id">
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field v-model="search" label="Search" single-line></v-text-field>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn color="primary" @click="importCSV">Import CSV</v-btn>
                        <v-btn color="primary" @click="addItem">Add Sponsor</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.firstname }}</td>
                    <td>{{ item.lastname }}</td>
                    <td>{{ item.email }}</td>
                    <td><v-btn @click="editItem(item)" color="primary" icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn @click="deleteItem(item)" color="error" icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>

            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                        <v-text-field v-model="editedItem.firstname" label="First Name"></v-text-field>
                        <v-text-field v-model="editedItem.lastname" label="Last Name"></v-text-field>
                        <v-text-field v-model="editedItem.email" label="email"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveItem" :disabled="!valid">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
  
<script>
import { parse } from "papaparse";
import { db } from '@/plugins/firebase'
import {
    collection,
    getDocs,
    deleteDoc,
    addDoc,
    doc,
    updateDoc,
    writeBatch
} from "@firebase/firestore";

export default {
    data() {
        return {
            headers: [
                { title: "Name", value: "name" },
                { title: "First Name", value: "firstname" },
                { title: "Last Name", value: "lastname" },
                { title: "email", value: "email" },
                { title: "Action", value: "action", sortable: false },
            ],
            items: [],
            search: "",
            singleSelect: false,
            loading: false,
            dialog: false,
            editedItem: {
                id: null,
                name: "",
                firstname: "",
                lastname: "",
                email: "",
            },
            valid: false,
        };
    },
    computed: {
        dialogTitle() {
            return this.editedItem.id ? "Edit Item" : "Add Item";
        },
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                const querySnapshot = await getDocs(collection(db, "sponsors"));
                this.items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        async deleteItem(item) {
            try {
                await deleteDoc(doc(db, "sponsors", item.id));
                this.fetchData();
            } catch (error) {
                console.error("Error deleting item:", error);
            }
        },
        addItem() {
            this.editedItem = {
                id: null,
                name: "",
                firstname: "",
                lastname: "",
                email: "",
            };
            this.valid = false;
            this.dialog = true;
        },
        editItem(item) {
            this.editedItem = { ...item };
            this.valid = true;
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        async saveItem() {
            try {
                if (this.editedItem.id) {
                    // Edit existing item
                    await updateDoc(doc(db, "sponsors", this.editedItem.id), {
                        name: this.editedItem.name,
                        firstname: this.editedItem.firstname,
                        lastname: this.editedItem.lastname,
                        email: this.editedItem.email,
                    });
                } else {
                    // Add new item
                    const docRef = await addDoc(collection(db, "sponsors"), {
                        name: this.editedItem.name,
                        firstname: this.editedItem.firstname,
                        lastname: this.editedItem.lastname,
                        email: this.editedItem.email,
                    });
                    this.editedItem.id = docRef.id;
                }

                this.closeDialog();
                this.fetchData();
            } catch (error) {
                console.error("Error saving item:", error);
            }
        },
        async importCSV() {
            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.accept = ".csv";
            fileInput.addEventListener("change", async (event) => {
                const file = event.target.files[0];
                if (file) {
                    try {
                        const result = await this.parseCSV(file);
                        await this.uploadData(result.data);
                    } catch (error) {
                        console.error("Error parsing or uploading CSV:", error);
                    }
                }
            });

            fileInput.click();
        },

        async parseCSV(file) {
            return new Promise((resolve, reject) => {
                parse(file, {
                    complete: (result) => resolve(result),
                    error: (error) => reject(error),
                    header: true,
                });
            });
        },

        async uploadData(data) {
            try {
                const collectionRef = collection(db, "collectors");
                const batch = writeBatch(db);

                data.forEach((item) => {

                    if (item.id) {
                        console.log(item)
                        const docRef = doc(collectionRef, item.id);
                        batch.set(docRef, item);
                    }
                });

                await batch.commit();
                this.fetchData();
            } catch (error) {
                console.error("Error uploading data:", error);
            }
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  
  