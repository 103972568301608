<template>
  <form>
    <div class="container">
      <h1>Register</h1>
      <p>Please fill in this form to create an account.</p>
      <hr>
      <label for="firstname"><b>First Name</b></label>
      <input v-model="firstname" type="text" placeholder="Enter first name" name="firstname" id="firstname" required>

      <label for="lastname"><b>Last Name</b></label>
      <input v-model="lastname" type="text" placeholder="Enter last name" name="lastname" id="lastname" required>

      <label for="email"><b>Email</b></label>
      <input v-model="email" type="text" placeholder="Enter email" name="email" id="email" required>

      <label for="invitationid"><b>Invitation ID</b></label>
      <input v-model="invitationid" type="text" placeholder="Enter invitation ID" name="invitationid" id="invitationid" required>

      <label for="psw"><b>Password</b></label>
      <input v-model="password1" type="password" placeholder="Enter Password" name="psw" id="psw" required>

      <label for="psw-repeat"><b>Repeat Password</b></label>
      <input v-model="password2" type="password" placeholder="Repeat Password" name="psw-repeat" id="psw-repeat" required>

      <hr>
      <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p>

      <button class="registerbtn" @click="register">Register</button>
    </div>
  </form>
</template>

<script>

import { auth, db } from '@/plugins/firebase'
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

export default {
  name: 'RegisterUser',
  props: {
    invitationId: String
  },
  components: {
  },
  data() {
    return {
      email: '',
      password1: '',
      password2: '',
      firstname: '',
      lastname: '',
      invitationid: '',
      validated: true,
    }
  },
  methods: {
    register(e) {
      e.preventDefault();
      if (this.validated) {
        createUserWithEmailAndPassword(auth, this.email, this.password1)
          .then((userCredential) => {
            // Signed up
            const user = userCredential.user;
            console.log(user)
            updateProfile(user, {
              displayName: `${this.firstname} ${this.lastname}`,

              // photoURL: "https://example.com/jane-q-user/profile.jpg"
            })
            .then(() => {
              setDoc(doc(db, "invitations-2024", user.uid), {
                name: `${this.firstname} ${this.lastname}`,
              }).then(() => {
                this.$store.commit('login', user)
                // this.$router.push('/landing')
                this.$router.push('/invitation')

              });
            }).catch((error) => {
              console.log(error)
            });
            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            // ..
          });
      }
    },
  },
  mounted() {
    this.invitationid = this.invitationId
  },
}
</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: black;
}

* {
  box-sizing: border-box;
}

/* Add padding to containers */
.container {
  padding: 16px;
  background-color: white;
  width: 100%;
  max-width: 350px;
}

/* Full-width input fields */
input[type=text],
input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus,
input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.registerbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.registerbtn:hover {
  opacity: 1;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
</style>
