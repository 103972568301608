<!-- FirestoreTable.vue -->
<template>
    <v-container>
        <v-card>
            <v-card-title>Add User</v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-text-field v-model="editedItem.firstname" label="First Name"></v-text-field>
                    <v-text-field v-model="editedItem.lastname" label="Last Name"></v-text-field>
                    <v-text-field v-model="editedItem.email" label="email"></v-text-field>
                </v-form>
                <v-data-table :headers="headers" :items="items" :search="search" :single-select="singleSelect"
                    :loading="loading" item-key="id">
                    <template v-slot:top>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="search" label="Search" single-line></v-text-field>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn class="ml-2" color="primary" @click="saveItem">Add</v-btn>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.firstname }}</td>
                            <td>{{ item.lastname }}</td>
                            <td>{{ item.email }}</td>
                            <td>
                                <v-btn @click="editItem(item)" color="primary" icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn @click="deleteItem(item)" color="error" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                <!-- <v-btn color="blue darken-1" text @click="saveItem" :disabled="!valid">Save</v-btn> -->
            </v-card-actions>
        </v-card>
    </v-container>
</template>
  
<script>
import { db } from '@/plugins/firebase'
import {
    collection,
    getDocs,
    deleteDoc,
    addDoc,
    doc,
    updateDoc,
} from "@firebase/firestore";


export default {
    name: 'EntityTable',
    props: {
        org: String
    },
    data() {
        return {
            userDialog: false,
            dialogAddUser: false,
            headers: [
                { title: "First Name", value: "firstname" },
                { title: "Last Name", value: "lastname" },
                { title: "email", value: "email" },
                { title: "Action", value: "action", sortable: false },
            ],
            items: [],
            search: "",
            singleSelect: false,
            loading: false,
            editedItem: {
                id: null,
                firstname: "",
                lastname: "",
                email: "",
            },
            valid: false,
        };
    },
    computed: {
        dialogTitle() {
            return this.editedItem.id ? "Edit Item" : "Add Item";
        },
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                const usersRef = collection(db, `entities/${this.org}/users`);
                // const q = query(usersRef, where("org", "==", this.org));
                const querySnapshot = await getDocs(usersRef);
                this.items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        async deleteItem(item) {
            try {
                await deleteDoc(doc(db, `entities/${this.org}/users`, item.id));
                this.fetchData();
            } catch (error) {
                console.error("Error deleting item:", error);
            }
        },
        addItem() {
            this.editedItem = {
                id: null,
                firstname: "",
                lastname: "",
                email: "",
            };
            this.valid = false;
            this.dialog = true;
        },
        editItem(item) {
            this.editedItem = { ...item };
            this.valid = true;

        },
        closeDialog() {
            this.$emit('closeDialog')
        },
        async saveItem() {
            try {
                if (this.editedItem.id) {
                    // Edit existing item
                    await updateDoc(doc(db, `entities/${this.org}/users`, this.editedItem.id), {
                        // id: this.editedItem.id,
                        firstname: this.editedItem.firstname,
                        lastname: this.editedItem.lastname,
                        email: this.editedItem.email,
                    });
                } else {
                    // Add new item
                    await addDoc(collection(db, `entities/${this.org}/users`), {
                        org: this.org,
                        firstname: this.editedItem.firstname,
                        lastname: this.editedItem.lastname,
                        email: this.editedItem.email,
                    });
                }
                // this.closeDialog();
                this.fetchData();
            } catch (error) {
                console.error("Error saving item:", error);
            }
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  
  