import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import { auth } from '@/plugins/firebase'
import router from '@/routes/index'
import { db } from '@/plugins/firebase'
import { doc, getDoc } from "firebase/firestore";


import { onAuthStateChanged } from "firebase/auth";

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files

import JsonCSV from 'vue-json-csv'



const vuetify = createVuetify({
  // theme: {
  //   defaultTheme: "dark",
  // },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa
    },
  },
  components,
  directives,
});

// Create a new store instance.
const store = createStore({
  state() {
    return {
      user: undefined,
      uid: '',
      name: '',
      admin: false,
      org: '',

    }
  },
  mutations: {
    async login(state, user) {
      state.user = user
      const idTokenResult = await user.getIdTokenResult()
      state.org = idTokenResult.claims.org
      state.admin = idTokenResult.claims.admin;
      state.uid = user.uid;
        
      const docRef = doc(db, "entities", state.org);
      
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        state.entity = data.entity;
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No info");
      }
    },
    logout(state) {
      state.user = undefined
    }
  }
})

router.beforeEach(async (to) => {
  if (
    // make sure the user is authenticated
    !store.state.user &&
    // ❗️ Avoid an infinite redirect
    (to.path !== '/login' && to.path!== '/admin')
  ) {
    // redirect the user to the login page
    return { name: 'login' }
  }
})

createApp(App)
  .component('downloadCsv', JsonCSV)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')

// .use(store)

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    store.commit('login', user)
    router.push('/dashboard')
    // ...
  } else {
    // User is signed out
    // ...
    store.commit('logout')
  }
});
