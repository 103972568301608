<template>
  <div class="invitation-container" :style="{ 'background-color': colour1 }">
    <div class="flip-card-inner" :id="`flip-card-inner-${this.id}`" :style="{ 'background-color': colour1 }">
      <div class="flip-card-front" id="flip-card-front">
        <div class="logo">
          <img  v-if="photo"  :src="photo" width="200" />
        </div>
        <div class="header1 invitation-font" :style="{ 'background-color': colour2 }">
          <span>VIP Invitation |&nbsp;</span><span>ADMITS ONE</span>
        </div>
        <div class="header2 invitation-font">
          <div class="entry-style">Unlimited entry from:</div>
          <div>Wednesday March 7th, 13:00</div>
          <div class="header3 invitation-font">
          <div>Gallery ART | 3124</div>
        </div>
        </div>

        <a class="invitation-font name-font">{{ name }}</a>
        <div class="qr-container">
          <canvas class="qrcode" :id="`canvas-${id}`"></canvas>
        </div>
      </div>
      <div class="flip-card-back" id="flip-card-back" :style="{ 'background-color': colour1 }">
        <h2>Conditions:</h2>
        <div>Here you can put more text</div>
        <div>Multiple lines with several conditions</div>
      </div>
    </div>
    <div class="conditions">
      <div class="invitation-font">These are some conditions about the invitation</div>
      <div>
        <button class="button-style" @click="onClick">
          <i class="material-icons info-icon">more_horiz</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'VipTemplate',
  props: {
    id: {String, default: '123456' },
    name: {String, default: 'John Smith'},
    colour1: String,
    colour2: String,
    photo: String,
  },
  data() {
    return {
      isLoaded: false,
      isClicked: true,
      rot: 0,
      // photoURL: '@/assets/images/logo.png',
    }
  },
  computed: {
    photoUrl() {      
      return this.photo ? URL.createObjectURL(this.photo) : null
    },
  },
  methods: {
    generateQR() {
      
      const canvas = document.getElementById(`canvas-${this.id}`)
      QRCode.toCanvas(canvas, this.id,
        {
          errorCorrectionLevel: 'H',
          version: 5,
          includeMargin: false,
        },
        function (error) {
          if (error) console.error(error)
        }.bind(this))
      this.isLoaded = true;
    },
    toggleClass(id, className) {
      const el = document.getElementById(id)
      el.classList.toggle(className)
    },
    onClick(e) {
      e.preventDefault()
      const content = document.getElementById(`flip-card-inner-${this.id}`);
      this.rot = this.rot === 0 ? 180 : 0;
      content.style = 'transform: rotateY(' + this.rot + 'deg)';

    }
  },
  mounted() {

    this.generateQR();

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
[v-cloak] {
  display: none;
}

.invitation-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  min-width: 300px;
  /* background-color: rgb(92, 128, 25); */
  text-align: center;
  border-radius: 15px;

}

.qr-container {
  display: flex;
  margin: auto;
  align-items: center;
  margin-bottom: 5px;

}

.button-style {
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  width: 26px;
  height: 26px;
  border-color: white;
  padding: 0px
}

.info-icon {
  border-radius: 100%;
  color: white;
}

.invitation-font {
  color: white;
  align-self: center;
  display: flex;
  align-items: center;
}

.name-font {
  font-size: x-large;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}

.entry-style {
  font-size: 12px;
  display: flex;
  gap: 10px
}

.conditions {
  font-size: 12px;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.logo {
  width: 100%;
  /* margin-bottom: 10px;
  margin-top: 20px; */
  height: 100%;
  min-height: 40px;
  margin-top: 5px;
}

.header1 {
  font-size: 20px;
  color: white;
  /* background-color: rgb(106, 146, 32); */
  width: 100%;
  height: 10%;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
}

.header2 {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 30px;
}

.header3 {
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  padding-top: 5px;
  border-top-style: solid;
  border-width: thin;
  margin-top: 5px;
  margin-right: auto;
}

.qrcode {
  border-radius: 10px;
  display: block;
  margin: 0 auto;

}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

/*
a {
  color: #42b983;
} */


.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  justify-content: flex-end;
  align-items: center;
  /* max-width: 400px; */
  /* width: 300px; */
  /* background-color: rgb(92, 128, 25); */
  text-align: center;
  border-radius: 15px;
  /* padding: 10px; */
  margin: 10px;
  width: 100%;
}

.rotate .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.flip-card-back {
  /* background-color: rgb(106, 146, 32); */
  color: white;
  transform: rotateY(180deg);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;

}
</style>
