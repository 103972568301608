<template>
  <form>
    <div class="container">
      <h1>Login</h1>
      <!-- <p>Please fill in this form to create an account.</p> -->
      <hr>

      <label for="email"><b>Email</b></label>
      <input v-model="email" type="text" placeholder="Enter Email" name="email" id="email" required>

      <label for="psw"><b>Password</b></label>
      <input v-model="password" type="password" placeholder="Enter Password" name="psw" id="psw" required>

      <hr>
      <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p>

      <button @click="login" class="registerbtn">Login</button>
    </div>

  </form>
</template>

<script>

import { auth } from '@/plugins/firebase'
import { signInWithEmailAndPassword } from "firebase/auth";


export default {
  name: 'LoginUser',
  components: {
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login(e) {
      e.preventDefault();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          this.$store.commit('login', user)
          this.$router.push('/dashboard')
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
        });
    },

  },
  beforeMount() {
    if (this.$store.state.user) {
      // User already logged in, go to invitation
      this.$store.commit('login', this.$store.state.user)
      this.$route.push('/dashboard')
    }
  },
  mounted() {
  },
}
</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: black;
}

* {
  box-sizing: border-box;
}

/* Add padding to containers */
.container {
  padding: 16px;
  background-color: white;
}

/* Full-width input fields */
input[type=text],
input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus,
input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.registerbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.registerbtn:hover {
  opacity: 1;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
</style>
