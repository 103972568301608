// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB5fWxsIxaUP0FIKPm3Y0b7B9vpI2u0d7Q",
//   authDomain: "art-fair-tokyo.firebaseapp.com",
//   projectId: "art-fair-tokyo",
//   storageBucket: "art-fair-tokyo.appspot.com",
//   messagingSenderId: "540870132191",
//   appId: "1:540870132191:web:80ee44bbfaf6039ecbc46a",
//   measurementId: "G-YK9SJHP2Y1"
// };
const firebaseConfig = {
  apiKey: "AIzaSyABav5ILmxr_AdBZkUq89qOZD13Izc57H4",
  authDomain: "curation-fair.firebaseapp.com",
  projectId: "curation-fair",
  storageBucket: "curation-fair.appspot.com",
  messagingSenderId: "672759760035",
  appId: "1:672759760035:web:7d698911190db03fddeb16",
  measurementId: "G-LH71JD1NZB"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const db = getFirestore(app);
// const auth = getAuth(app);
// const storage = getStorage(app);

const dev = false

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

if (dev) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8081)
  connectStorageEmulator(storage, 'localhost', 9199)
}

export { db, auth, storage }
// export {analytics, db, auth, storage}
