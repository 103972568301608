import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
import LoginAdmin from '../views/LoginAdmin.vue';
import Register from '../views/Register.vue';
import VipInvitation from '../views/VipInvitation.vue';
// import Home from '../views/Home.vue';
import MiddlePage from '../views/MiddlePage'
import MgmtDashboard from '../views/MgmtDashboard'
import ManageGalleries from '../views/ManageGalleries'
import ManageCollectors from '../views/ManageCollectors'
import ManageInvitations from '../views/ManageInvitationsSimple'
// import ManageInvitations from '../views/ManageInvitations'
import CreateEvents from '../views/CreateEvents'
import ManageSponsors from '../views/ManageSponsors'
import SystemSettings from '../views/SystemSettings'
import ManageEntities from '../views/ManageEntities'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginAdmin
  },
  {
    path: '/admin',
    name: 'admin',
    component: LoginAdmin
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: MgmtDashboard
  },
  {
    path: '/entities',
    name: 'entities',
    component: ManageEntities
  },
  {
    path: '/galleries',
    name: 'galleries',
    component: ManageGalleries
  },
  {
    path: '/sponsors',
    name: 'sponsors',
    component: ManageSponsors 
  },
  {
    path: '/collectors',
    name: 'collectors',
    component: ManageCollectors
  },
  {
    path: '/events',
    name: 'events',
    component: CreateEvents
  },
  {
    path: '/invitations',
    name: 'invitations',
    component: ManageInvitations
  },
  {
    path: '/settings',
    name: 'settings',
    component: SystemSettings
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register/:invitationId',
    name: 'Register',
    component: Register,
    props: true
  },
  {
    path: '/invitation',
    name: 'VipInvitation',
    component: VipInvitation
  },
  {
    path: '/landing',
    name: 'MiddlePage',
    component: MiddlePage
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
