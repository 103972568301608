<template>
  <CreateEvent :entityId="entityId" />
</template>
  
<script>

import CreateEvent from '@/components/CreateEvent.vue';
// import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

export default {
  name: 'SendInvitations',
  props: {
    invitationId: String
  },
  components: {
    CreateEvent
  },
  data() {
    return {
      entityId: '00IIRm2K8deNUTINZnyJ'
    }
  },
  methods: {

  },
  mounted() {
  },
}
</script>
  
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: black;
}

* {
  box-sizing: border-box;
}

/* Add padding to containers */
.container {
  padding: 16px;
  background-color: white;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

/* Full-width input fields */
input[type=text],
input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus,
input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.registerbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.registerbtn:hover {
  opacity: 1;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
</style>
  