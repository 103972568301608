<!-- CreateEvent.vue -->
<template>
    <v-container>
        <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" location="top">
            {{ message }}
            <template v-slot:actions>
                <v-btn color="primary" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col cols="8">
                <v-select :items="events" item-value="id" item-title="info.eventName" label="Load Event" 
                            v-model="eventSelected"  @update:model-value="loadEvent">
                </v-select>
            </v-col>
            <v-col cols="4">
                <v-btn v-if="disableForm" class="mr-2" size="x-large" color="warning" @click="resetForm">Reset</v-btn>
                <v-btn v-if="disableForm" color="primary" size="x-large" @click="updateEvent">Update</v-btn>

            </v-col>
        </v-row>
        <v-form @submit.prevent="createEvent" ref="eventForm" :disabled="disableForm">

            <h2>Event</h2>
            <v-row>
                <v-col>
                    <v-text-field :disabled="false" v-model="eventName" label="Event Name"></v-text-field>
                </v-col>
                <v-col>
                    <v-select :items="sectionItems" label="Number of Sections" v-model="nSections">

                    </v-select>
                </v-col>
            </v-row>
            <v-row>

                <v-col>

                    <v-text-field v-model="eventStartDate" label="Start Date" type="date"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field v-model="eventEndDate" label="End Date" type="date"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-row v-for="index in nSections" :key="index">
                    <h2>{{ `Section ${index}` }}</h2>
                    <v-divider class="mb-2 border-opacity-100" :thickness="3"></v-divider>

                    <v-row>
                        <v-col cols="8">
                            <v-text-field :disabled="false" v-model="sectionName[index - 1]" label="Section Name"></v-text-field>
                            <v-col>
                                <h3>Period</h3>
                                <v-text-field v-model="sectionStartDate[index - 1]" label="Start Date"
                                    type="date"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="sectionEndDate[index - 1]" label="End Date"
                                    type="date"></v-text-field>
                            </v-col>
                            <v-divider></v-divider>
                            <v-row class="mb-2">
                                <v-col cols="6">
                                    <h3>VIP Time Slots</h3>
                                    <v-btn color="primary" @click="generateSlots(index - 1)">Create Timeslots</v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <h3>Logo </h3>
                                    <input type="file" @change="handleFileChange($event, index - 1)" accept="image/*">
                                </v-col>

                                <!-- <v-col>
                                    <h3>Invitations</h3>
                                    <v-text-field v-model="nInvitations[index - 1]" type="Number">
                                    </v-text-field>
                                </v-col> -->
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="mb-2 mt-2">
                                <v-col cols="6">
                                    <h3>Primary Colour </h3>
                                    <v-color-picker hide-canvas v-model="colour1[index - 1]"></v-color-picker>
                                </v-col>
                                <v-col cols="6">
                                    <h3>Secondary Colour </h3>
                                    <v-color-picker hide-canvas v-model="colour2[index - 1]"></v-color-picker>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col class="mb-2" style="display: flex; min-width;: 300px" cols="4">
                            <InvitationTemplate :id="`index-${index}`" :key="index" :photo="logoUrl[index - 1]"
                                :colour1="colour1[index - 1]" :colour2="colour2[index - 1]" />
                        </v-col>
                    </v-row>


                    <v-divider class="mb-2 border-opacity-100" :thickness="3"></v-divider>

                    <v-dialog height="70vh" scrollable v-model="dialog[index - 1]" max-width="600px">
                        <v-card>
                            <v-card-title>SLOTS</v-card-title>
                            <v-card-text>
                                <div v-for="iS in sectionDiffDays[index - 1]" :key="iS" cols="12">
                                    <v-checkbox v-model="enabledSlots[index - 1][iS - 1]" :label="`Day
                                            ${calculateDate(index - 1, iS - 1)}`"></v-checkbox>
                                    <div v-if="enabledSlots[index - 1][iS - 1]">
                                        <v-row>
                                            <v-col cols="3">
                                                <v-text-field v-model="nTimeslots[index - 1][iS - 1]" label="Slots"
                                                    @update:model-value="resetSlot(index - 1, iS - 1)"
                                                    type="number"></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field v-model="slotCapacity[index - 1][iS - 1]" label="Capacity"
                                                    type="number"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select :items="slotDurations" label="Duration"
                                                    @update:model-value="resetSlot(index - 1, iS - 1)"
                                                    v-model="slotDuration[index - 1][iS - 1]">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="startTime[index - 1][iS - 1]" label="From"
                                                    type="time"
                                                    @update:model-value="calculateEndTime(index - 1, iS - 1)"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="endTime[index - 1][iS - 1]" label="To" type="time"
                                                    disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog[index - 1] = false">Close</v-btn>
                                <v-btn :disabled="disableForm" color="blue darken-1" text @click="dialog[index - 1] = false">Save</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>

            </v-row>
            <v-row>
                <v-col>
                    <v-btn :disabled="disableForm" type="submit" color="success">Create Event</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="overlay" max-width="600px">
            <v-card class="text-center">
                <v-card-title>Creating event...</v-card-title>
                <v-card-text>
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
  
<script>
import { db, storage } from "@/plugins/firebase";
import { collection, getDocs, writeBatch } from "@firebase/firestore";
import { setDoc, updateDoc, doc } from "firebase/firestore";
import InvitationTemplate from "./InvitationTemplate.vue";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';


export default {
    props: {
        entityId: String,
    },
    data() {
        return {
            disableForm: false,
            events: [],
            overlay: false,
            snackbarColor: 'warning',
            loading: false,
            eventSelected: '',
            eventList: [],
            eventStartDate: '',
            eventEndDate: '',
            timeout: 4000,
            message: '',
            sectionName: ['', '', '', ''],
            snackbar: false,
            nInvitations: [100000, 100000, 100000, 100000],
            enabledSlots: [[], [], [], []],
            slotDuration: [[], [], [], []],
            slotCapacity: [[], [], [], []],
            slotDurations: [30, 60, 90, 120],
            dialog: [false, false, false, false],
            nTimeslots: [[], [], [], []],
            colour1: ['#5D8019', '#5D8019', '#5D8019', '#5D8019'],
            colour2: ['#699221', '#699221', '#699221', '#699221'],
            logoUrl: [null, null, null, null],
            logoFile: ['', '', '', ''],
            sectionStartDate: ['', '', '', ''],
            sectionEndDate: ['', '', '', ''],
            startTime: [[], [], [], []],
            endTime: [[], [], [], []],
            sectionDiffDays: [1, 1, 1, 1],
            sectionTimeSlots: [],
            selectedTime: [],
            sectionItems: [1, 2, 3, 4],
            eventName: "",
            timeslots: [[], [], [], []],
            nSections: 1,
            sections: [],
            collectorsLoaded: false,
            guestList: [],
            tableHeaders: [
                { title: "First Name", value: "firstname" },
                { title: "Last Name", value: "lastname" },
                { title: "Email", value: "email" },
                { title: "Confirm Attendance", value: "confirmAttendance" },
            ],
            selectedCollectors: [],
            logoBucketUrl: ['', '', '', ''],
        };
    },
    methods: {
        async updateEvent(){
            this.overlay = true;
            for (let s = 0; s < this.nSections; s++) {
                if (this.logoFile[s])
                    this.logoBucketUrl[s] = await this.uploadFileToFirebase(this.logoFile[s], this.loadedEventId);
                else
                    this.logoBucketUrl[s] = this.logoUrl[s]
            }

            // Update event name
            const eventInfo = {
                eventName: this.eventName,
            }

            try {
                const docRefEvents = doc(db, `events/${this.entityId}/master/${this.loadedEventId}`);
                await setDoc(docRefEvents, { info: eventInfo }, {merge: true});
            } catch (error) {
                console.error("Error creating event:", error);
            }

            let sectionList = {}
            // Update section name and colours
            for (let s = 0; s < this.nSections; s++) {
                // General information about the section

                const sectionId = `${this.loadedEventId}_${s}`
                // Initialise the slots with their capacity

                
                const sectionObject = {
                    sectionName: this.sectionName[s],                  
                    colour1: this.colour1[s],
                    colour2: this.colour2[s],
                    logoUrl: this.logoBucketUrl[s],
                }

                sectionList[sectionId] = {
                    name: this.sectionName[s],
                    invitations: this.nInvitations[s]
                }

                const sectionDocRef = doc(db, `events/${this.entityId}/master/${this.loadedEventId}/sections/${sectionId}`);
                await setDoc(sectionDocRef, sectionObject, {merge: true});
            }

            try {
                // Create the event in the master event section
                const docRefEvents = doc(db, `events/${this.entityId}/master/${this.loadedEventId}`);
                await setDoc(docRefEvents, { sections: sectionList }, {merge: true});
                this.openSnackbar('Event created', 'success')
            }
            catch (error) {
                console.error("Error creating event:", error);
                this.openSnackbar('Unable to create event', 'warning')
            }
            this.eventSelected = ''
            this.$refs.eventForm.reset()
            this.resetForm()
            this.overlay = false;
            this.fetchEventList()

        },
        resetForm(){
            this.$refs.eventForm.reset();
            this.disableForm = false;
            this.nSections = 1;
            this.eventSelected = '';
            this.colour1 = ['#5D8019', '#5D8019', '#5D8019', '#5D8019'];
            this.colour2 = ['#699221', '#699221', '#699221', '#699221'];
            this.logoUrl = [null, null, null, null];
            this.logoFile = ['', '', '', ''];
            this.enabledSlots = [[], [], [], []];
            this.slotDuration = [[], [], [], []];
            this.slotCapacity = [[], [], [], []];
            this.timeslots = [[], [], [], []];
        },  
        async uploadFileToFirebase(file, eventId, sectionId) {
            try {
                const fileInfo = file.name.split('.')
                const extension = fileInfo[fileInfo.length - 1]
                const storageRef = ref(storage, `logos/${eventId}/${sectionId}.${extension}`);

                // Upload the file to Firebase Storage
                await uploadBytes(storageRef, file);

                // Get the download URL of the uploaded file
                const downloadURL = await getDownloadURL(storageRef);

                // Set the URL in the component's data
                return downloadURL;
            } catch (error) {
                console.error('Error uploading file to Firebase Storage:', error);
            }
        },
        openSnackbar(message, color) {
            this.snackbarColor = color;
            this.message = message
            this.snackbar = true;
        },
        generateSlots(i) {
            if (this.sectionStartDate[i] == '' || this.sectionEndDate[i] == '') {
                this.openSnackbar('choose the dates first', 'error')
                return
            }
            this.calculateDayDiff(i)
            for (let d = 0; d < this.sectionDiffDays[i]; d++) {
                this.startTime[i].push('')
                this.endTime[i].push('')
            }
            this.dialog[i] = true;


        },
        resetSlot(i, d) {
            this.startTime[i][d] = ''
            this.endTime[i][d] = ''
        },
        getEndTime(startTime, slotDuration) {
            const t = startTime.split(':')
            let hours = parseInt(t[0]) + Math.floor(slotDuration / 60)
            let minutes = slotDuration % 60 + parseInt(t[1])
            if (minutes >= 60) {
                hours += Math.floor(minutes / 60);
                minutes = minutes % 60;
            }

            // console.log(new Date(this.tartTime[i][d].getTime() + totalMinutes*60000));
            const endTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
            return endTime;
        },
        calculateEndTime(i, d) {
            const t = this.startTime[i][d].split(':')
            const slotDuration = this.slotDuration[i][d]
            const nTimeslots = this.nTimeslots[i][d]
            const totalMinutes = slotDuration * nTimeslots
            let hours = parseInt(t[0]) + Math.floor(totalMinutes / 60)
            let minutes = totalMinutes % 60 + parseInt(t[1])
            if (minutes >= 60) {
                hours += Math.floor(minutes / 60);
                minutes = minutes % 60;
            }
            // console.log(new Date(this.tartTime[i][d].getTime() + totalMinutes*60000));
            this.endTime[i][d] = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        },
        calculateDate(i, d) {
            const start = new Date(this.sectionStartDate[i]);
            start.setDate(start.getDate() + d);
            return start.toLocaleDateString('ja').replaceAll('/', '-');
        },
        calculateDayDiff(i) {
            const start = new Date(this.sectionStartDate[i]);
            const end = new Date(this.sectionEndDate[i]);
            this.sectionDiffDays[i] = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1;


        },
        handleFileChange(e, i) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.logoUrl[i] = URL.createObjectURL(files[0])
            this.logoFile[i] = files[0]
            // this.logoUrl.splice(i, 0, URL.createObjectURL(files[0]));
        },
        async createEvent() {
            // Upload logos to bucket
            this.overlay = true;
            const eventId = Math.floor(Date.now() / 1000).toString();
            
            for (let s = 0; s < this.nSections; s++) {
                const sectionId = `${eventId}_${s}`
                this.logoBucketUrl[s] = await this.uploadFileToFirebase(this.logoFile[s], eventId, sectionId);
            }

            const eventInfo = {
                eventName: this.eventName,
                startDate: this.eventStartDate,
                endDate: this.eventEndDate,
                nSections: this.nSections,
                start: new Date(this.eventStartDate),
                end: new Date(this.eventEndDate),
            }

            try {
                // Create the event in the master event section
                const docRefEvents = doc(db, `events/${this.entityId}/master/${eventId}`);
                await setDoc(docRefEvents, { info: eventInfo });
            }
            catch (error) {
                console.error("Error creating event:", error);
            }

            let sectionList = {}
            const sectionsBatch = writeBatch(db);
            
            const sectionDates = {}
            for (let s = 0; s < this.nSections; s++) {
                // General information about the section

                const sectionId = `${eventId}_${s}`
                // Initialise the slots with their capacity

                // let sectionSlot = {}
                const slotsBatch = writeBatch(db);
                const capacityBatch = writeBatch(db);

                sectionDates[sectionId] = []
                for (let d = 0; d < this.sectionDiffDays[s]; d++) {
                    if (this.enabledSlots[s][d]) {
                        const slotDate = this.calculateDate(s, d)
                        const sectionDayId = `${sectionId}_${slotDate}`
                        sectionDates[sectionId].push({ id: sectionDayId, name: slotDate, 
                                                    nTimeslots: this.nTimeslots[s][d], 
                                                    slotCapacity: +this.slotCapacity[s][d],
                                                    slotDurations: this.slotDuration[s][d],
                                                    startTime: this.startTime[s][d] })
                        // const slotDocRef = doc(db, 'events', eventId, 'sections', sectionId, 'slots', sectionDayId);//doc(slotSubcollectionRef, sectionDayId);                      
                        const slotDocRef = doc(db, `events/${this.entityId}/master/${eventId}/sections/${sectionId}/slots/${sectionDayId}`)
                        const capacityDocRef = doc(db, `slots/${eventId}/sections/${sectionId}/slots/${sectionDayId}`)
                        let startTime = this.startTime[s][d]
                        let slotObject = {}
                        for (let t = 0; t < this.nTimeslots[s][d]; t++) {
                            const endTime = this.getEndTime(startTime, this.slotDuration[s][d]);
                            const slotId = `${startTime}_${endTime}`
                            slotObject[`${slotId}`] = +this.slotCapacity[s][d]
                            startTime = endTime;
                        }
                        slotsBatch.set(slotDocRef, slotObject);
                        capacityBatch.set(capacityDocRef, slotObject)
                    }
                }
                const sectionObject = {
                    sectionName: this.sectionName[s],
                    sectionStartDate: this.sectionStartDate[s],
                    sectionEndDate: this.sectionEndDate[s],
                    colour1: this.colour1[s],
                    colour2: this.colour2[s],
                    logoUrl: this.logoBucketUrl[s],
                    nTimeslots: this.nTimeslots[s], 
                    slotCapacity: +this.slotCapacity[s],
                    slotDurations: this.slotDuration[s],
                    startTime: this.startTime[s],
                    enabledSlots: this.enabledSlots[s]
                }

                sectionList[sectionId] = {
                    name: this.sectionName[s],
                    invitations: this.nInvitations[s],
                    logoUrl: this.logoBucketUrl[s]
                }

                const sectionDocRef = doc(db, `events/${this.entityId}/master/${eventId}/sections/${sectionId}`);
                sectionsBatch.set(sectionDocRef, sectionObject);
  
                await slotsBatch.commit();
                await capacityBatch.commit();
            }
            await sectionsBatch.commit();
            try {
                // Create the event in the master event section
                const docRefEvents = doc(db, `events/${this.entityId}/master/${eventId}`);
                await updateDoc(docRefEvents, { sections: sectionList });
                await updateDoc(docRefEvents, { dates: sectionDates });
                this.openSnackbar('Event created', 'success')
            }
            catch (error) {
                console.error("Error creating event:", error);
                this.openSnackbar('Unable to create event', 'warning')
            }
            this.$refs.eventForm.reset()
            this.resetForm()
            this.overlay = false;



        },
        async loadEvent(eventId) {
            this.loadedEventId = eventId
            this.$refs.eventForm.reset()
            this.loading = true;
            try {
                const eventIndex = this.events.findIndex(o => o.id == eventId)
                // const eventIndex = this.eventList.findIndex(this.eventSelected)
                const eventInfo = this.events[eventIndex].info
                // const eventDates = this.events[eventIndex].dates

                // Load event info
                this.nSections = eventInfo.nSections;
                this.eventName = eventInfo.eventName;
                this.eventStartDate = eventInfo.startDate;
                this.eventEndDate = eventInfo.endDate;

                // Load section info
                const querySnapshotSections = await getDocs(collection(db, `events/${this.entityId}/master/${eventId}/sections`));
                let index = 0
                querySnapshotSections.forEach(async (doc) => {
                    
                    const data = doc.data()
                    this.colour1[index] = data.colour1
                    this.colour2[index] = data.colour2
                    this.sectionStartDate[index] = data.sectionStartDate
                    this.sectionEndDate[index] = data.sectionEndDate
                    this.sectionName[index] = data.sectionName
                    this.logoUrl[index] = data.logoUrl
                    this.nTimeslots[index] = data.nTimeslots 
                    this.slotCapacity[index] = +data.slotCapacity
                    this.slotDuration[index] = data.slotDurations
                    this.startTime[index] = data.startTime
                    this.enabledSlots[index] = data.enabledSlots
                    
                    index += 1;

                })


            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
                this.disableForm = true;
            }
        },
        async fetchEventList() {
            this.loading = true;
            try {
                const querySnapshot = await getDocs(collection(db, `events/${this.entityId}/master`));
                this.events = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                this.eventList = querySnapshot.docs.map(doc => doc.data().eventName);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    components: { InvitationTemplate },
    mounted() {
        this.fetchEventList()
    }
};
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  