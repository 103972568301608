<!-- Navbar.vue -->
<template>
  <v-app-bar app color="primary">
    <v-toolbar-title>Management</v-toolbar-title>
    <v-spacer></v-spacer>

    <!-- <v-app-bar-nav-icon @click="toggleMenu"><v-btn icon="mdi-menu" color="white"></v-btn></v-app-bar-nav-icon> -->
    <v-menu  offset-y="3">
      <template v-slot:activator="{ props }">
              <v-btn icon="mdi-menu" v-bind="props"></v-btn>
            </template>
      <v-list v-if="$store.state.user">
        <v-list-item  v-for="(item, index) in menuItemsLoggedin" :key="index" @click="item.logout ? logout() : navigateTo(item.route)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item v-for="(item, index) in menuItemsLoggedOut" :key="index" @click="item.logout ? logout() : navigateTo(item.route)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { auth } from '@/plugins/firebase'
import { signOut } from "firebase/auth";


export default {
  data() {
    return {
      drawer: false,
      menu: true,
      // menuItemsLoggedin: [
      //   { text: 'Dashboard', route: '/dashboard' },
      //   { text: 'Entities', route: '/entities' },
      //   // { text: 'Galleries', route: '/galleries' },
      //   // { text: 'Sponsors', route: '/sponsors' },
      //   { text: 'Collectors', route: '/collectors' },
      //   { text: 'Invitations', route: '/invitations' },
      //   { text: 'Create Event', route: '/events' },
      //   { text: 'Settings', route: '/settings' },
      //   { text: 'Logout', route: '/logout', logout: true },
      //   // Add more menu items as needed
      // ],
      menuItemsLoggedOut: [
        { text: 'Login', route: '/login' },
        // Add more menu items as needed
      ],
    };
  },
  computed: {
    menuItemsLoggedin() {
      if (this.$store.state.admin) {return [
        { text: 'Dashboard', route: '/dashboard' },
        { text: 'Create Event', route: '/events' },
        { text: 'Entities', route: '/entities' },
        { text: 'Collectors', route: '/collectors' },
        { text: 'Invitations', route: '/invitations' },       
        { text: 'Settings', route: '/settings' },
        { text: 'Logout', route: '/logout', logout: true },
        // Add more menu items as needed
      ]} else 
      return [
        { text: 'Dashboard', route: '/dashboard' },
        { text: 'Collectors', route: '/collectors' },
        { text: 'Invitations', route: '/invitations' },
        // { text: 'Settings', route: '/settings' },
        { text: 'Logout', route: '/logout', logout: true },
        // Add more menu items as needed
      ]
    }
  },
  methods: {
    logout() {
      signOut(auth)
      this.$store.commit('logout');
      this.$router.push('login')
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    navigateTo(route) {
      this.$router.push(route);
      this.menu = false;
    },
  },
};
</script>
