<!-- FirestoreTable.vue -->
<template>
    <v-container>
        <v-data-table :headers="headers" :items="items" :search="search" :single-select="singleSelect" :loading="loading"
            item-key="id">
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field v-model="search" label="Search" single-line></v-text-field>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn color="primary" @click="importCSV">Import CSV
                            <v-icon end icon="mdi-upload"></v-icon>
                        </v-btn>
                        <v-btn class="ml-2" color="primary" @click="downloadCSVData">Export CSV</v-btn>
                        <!-- <v-btn class="ml-2" color="primary">
                            <download-csv :data="items" name="entities.csv"
                                :fields="['id', 'type', 'entity', 'firstname', 'lastname', 'email']">
                                Export CSV

                                <v-icon end icon="mdi-download"></v-icon>
                            </download-csv>
                        </v-btn> -->
                        <v-btn class="ml-2" color="primary" @click="addItem">Add</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.type }}</td>
                    <td>{{ item.entity }}</td>
                    <td>{{ item.firstname }}</td>
                    <td>{{ item.lastname }}</td>
                    <td>{{ item.email }}</td>
                    <td>
                        <v-btn @click="editItem(item)" color="primary" icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="item.id !== '00IIRm2K8deNUTINZnyJ'" @click="confirmDelete(item)" color="error" icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn @click="addUsers(item)" color="warning" icon>
                            <v-icon>mdi-account</v-icon>
                        </v-btn>
                    </td>
                </tr>

            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>{{ dialogTitle }}</v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-select v-model="editedItem.type" :items="entityTypes" label="Type"></v-select>
                        <v-text-field v-model="editedItem.entity" label="Entity"></v-text-field>
                        <v-text-field v-model="editedItem.firstname" label="First Name"></v-text-field>
                        <v-text-field v-model="editedItem.lastname" label="Last Name"></v-text-field>
                        <v-text-field v-model="editedItem.email" label="email"></v-text-field>
                    </v-form>
                </v-card-text>
                <h3>{{ message }}</h3>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveItem" :disabled="!valid">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="userDialog" max-width="600px">
            <user-table @closeDialog="closeUserDialog" :org="editedItem.id"/>
        </v-dialog>
        <v-dialog v-model="confirmationDialog" max-width="500px">
            <v-card>
                <v-card-title>Are you sure?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="cancelDelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="deleteItem()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
  
<script>
import { parse } from "papaparse";
import { db } from '@/plugins/firebase'
import {
    collection,
    getDocs,
    deleteDoc,
    addDoc,
    doc,
    updateDoc,
    writeBatch,
} from "@firebase/firestore";
import UserTable from "./UserTable.vue";


export default {
    name: 'EntityTable',
    data() {
        return {
            toDelete: '',
            confirmationDialog: false,
            message: "",
            userDialog: false,
            dialogAddUser: false,
            headers: [
                { title: "Type", value: "type" },
                { title: "Entity", value: "entity" },
                { title: "First Name", value: "firstname" },
                { title: "Last Name", value: "lastname" },
                { title: "email", value: "email" },
                { title: "Action", value: "action", sortable: false },
            ],
            entityTypes: ['Gallery', 'Sponsor'],
            items: [],
            search: "",
            singleSelect: false,
            loading: false,
            dialog: false,
            editedItem: {
                id: null,
                firstname: "",
                lastname: "",
                email: "",
            },
            valid: false,
            csvColumns: ['id', 'type', 'entity', 'firstname', 'lastname', 'email'],
        };
    },
    computed: {
        dialogTitle() {
            return this.editedItem.id ? "Edit Item" : "Add Item";
        },
    },
    methods: {
        downloadCSVData() {
            let csv = `${this.csvColumns.join(',')}\n`;
            this.items.forEach((row) => {
                this.csvColumns.forEach((column, index) => {
                    csv += `${row[column]}`
                    if (index < this.csvColumns.length - 1)
                        csv += ','
                })
                csv += "\n";
        
            });
        
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'entities.csv';
            anchor.click();
        },
        closeUserDialog() {
            this.userDialog = false;
        },
        checkNotExisting(name, email) {
            const emailCheck = this.items.find((entity) => entity.email == email || entity.entity == name)
            return emailCheck && email != "" ? false : true
        },
        async fetchData() {
            this.loading = true;
            try {
                const querySnapshot = await getDocs(collection(db, 'entities'));
                this.items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            }
            catch (error) {
                console.error("Error fetching data:", error);
            }
            finally {
                this.loading = false;
            }
        },
        cancelDelete(){
            this.toDelete = '';
            this.confirmationDialog = false;
        },
        confirmDelete(item) {
            this.toDelete = item;
            this.confirmationDialog = true;
        },
        async deleteItem() {
            try {
                await deleteDoc(doc(db, 'entities', this.toDelete.id));
                this.confirmationDialog = ''
                this.fetchData();
                this.confirmationDialog = false;
            }
            catch (error) {
                console.error("Error deleting item:", error);
            }
        },
        addItem() {
            this.editedItem = {
                id: null,
                type: "",
                entity: "",
                firstname: "",
                lastname: "",
                email: "",
            };
            this.valid = false;
            this.dialog = true;
        },
        editItem(item) {
            this.editedItem = { ...item };
            this.valid = true;
            this.dialog = true;
        },
        addUsers(item) {
            this.editedItem = { ...item };
            this.valid = true;
            this.userDialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        async saveItem() {
            try {
                if (this.editedItem.id) {
                    // Edit existing item
                    await updateDoc(doc(db, 'entities', this.editedItem.id), {
                        type: this.editedItem.type,
                        entity: this.editedItem.entity,
                        firstname: this.editedItem.firstname,
                        lastname: this.editedItem.lastname,
                        email: this.editedItem.email,
                    });
                    this.closeDialog();
                    this.fetchData();
                }
                else {
                    if (this.checkNotExisting(this.editedItem.entity, this.editedItem.email)){
                        // Add new item
                        const docRef = await addDoc(collection(db, 'entities'), {
                            type: this.editedItem.type,
                            entity: this.editedItem.entity,
                            firstname: this.editedItem.firstname,
                            lastname: this.editedItem.lastname,
                            email: this.editedItem.email,
                        });
                        this.editedItem.id = docRef.id;
                        await addDoc(collection(db, `entities/${docRef.id}/users`), {
                            org: docRef.id,
                            firstname: this.editedItem.firstname,
                            lastname: this.editedItem.lastname,
                            email: this.editedItem.email,
                        });
                        this.closeDialog();
                        this.fetchData();
                    } else {
                        this.message = "Entity already exists"
                    }
                }
                
            }
            catch (error) {
                console.error("Error saving item:", error);
            }
        },
        async importCSV() {
            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.accept = ".csv";
            fileInput.addEventListener("change", async (event) => {
                const file = event.target.files[0];
                if (file) {
                    try {
                        const result = await this.parseCSV(file);
                        await this.uploadData(result.data);
                    }
                    catch (error) {
                        console.error("Error parsing or uploading CSV:", error);
                    }
                }
            });
            fileInput.click();
        },
        async exportCSV() {
            console.log('Export CSV pending');
        },
        async parseCSV(file) {
            return new Promise((resolve, reject) => {
                parse(file, {
                    complete: (result) => resolve(result),
                    error: (error) => reject(error),
                    header: true,
                });
            });
        },
        async uploadData(data) {
            try {
                const collectionRef = collection(db, 'entities');
                const batch = writeBatch(db);
                // data.forEach((item) => {
                //     if (item.id) {
                //         const docRef = doc(collectionRef, item.id);
                //         delete item.id;
                //         batch.set(docRef, item);
                //     }
                // });
                data.forEach((item) => {
                    if (item.id) 
                        delete item.id;
                    const docRef = doc(collectionRef);
                    batch.set(docRef, item);
                });
                await batch.commit();
                this.fetchData();
            }
            catch (error) {
                console.error("Error uploading data:", error);
            }
        },
    },
    mounted() {
        this.fetchData();
    },
    components: { UserTable }
};
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  
  