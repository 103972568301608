<template>
  <div class="invitation-wrapper">
    <VipTemplate v-if="id !== ''" :id="$store.state.uid" :name="$store.state.name"/>
  </div>
</template>

<script>

import VipTemplate from '@/components/VipTemplate.vue';
// import { db } from '@/plugins/firebase'
// import { doc, getDoc } from "firebase/firestore";

export default {
  name: 'VipInvitation',
  components: {
    VipTemplate
  },
  data() {
    return {
      id: '',
      name: ''
    }
  },
  methods: {
    async getInfo() {
      //LlennbYpeIU59cG109Zn
      //fYkQoy87jBGAPdzgRq0i

      // const info = window.location.search.slice(3).split('-');

      // console.log(this.$store.state.user.uid)
      // const docRef = doc(db, "invitations-2024", this.$store.state.user.uid);
      this.id = this.$store.state.uid;
      this.name = this.$store.state.name;
      console.log(this.id, this.name)
      // const docSnap = await getDoc(docRef);

      // if (docSnap.exists()) {
      //   const data = docSnap.data();
      //   this.name = data.name;

      //   this.isLoaded = true;
      // } else {
      //   // docSnap.data() will be undefined in this case
      //   console.log("Error");
      //   this.isLoaded = false;
      // }
    },
  },
  beforeMount() {
    if (this.$store.state.user) {
      // User already logged in, go to invitation
      this.getInfo()
    }
  },
  mounted() {
    if (this.$store.state.user) {
      // User already logged in, go to invitation
      console.log(this.$store.state.user)
      this.getInfo()
    } else {
      this.$router.push('/dashboard')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.invitation-wrapper {
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  max-width: 350px;
}

</style>
