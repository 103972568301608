<template>
  <v-app>
    <Navbar />
    <v-main class="main">
      <router-view />
    </v-main>

  </v-app>
</template>

<script>
// import VipInvitation from '@/views/VipInvitation.vue'
import { auth } from '@/plugins/firebase'
import { signOut } from "firebase/auth";
import Navbar from "@/components/NavBar";


export default {
  name: 'App',
  components: {
    Navbar
  },
  methods: {
    logout() {
      console.log()
      signOut(auth)
      this.$store.commit('logout');
      this.$router.push('login')
    }
  },
  mounted(){
    // if (!auth.currentUser){
    //   this.$router.push('login')
    // }
    
  }
}
</script>

<style>
.main{
  height: 110%;
}

</style>
