<template>
  <button @click="showInvitation">See my invitation</button>
</template>

<script>
export default {
  name: 'MiddlePage',
  components: {
  },
  data() {

  },
  methods: {
    showInvitation() {
      this.$router.push('invitation')
    }
  }
}
</script>
