<!-- UserManagement.vue -->
<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>{{ isEditing ? 'Edit User' : 'Add User' }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-text-field v-model="editedUser.firstName" label="First Name" required></v-text-field>
            </v-row>
            <v-row>
              <v-text-field v-model="editedUser.lastName" label="Last Name" required></v-text-field>
            </v-row>
            <v-row v-if="!isEditing">
              <v-text-field v-model="editedUser.email" label="Email" required></v-text-field>
            </v-row>
            <!-- <v-row>
              <v-text-field v-model="editedUser.position" label="Position" required></v-text-field>
            </v-row> -->
            <!-- <v-row>
              <v-select v-model="editedUser.profile" :items="profileOptions" label="Profile"></v-select>
            </v-row> -->
            <v-row class="mb-3">
              <v-spacer></v-spacer>
              <v-btn @click="closeDialog">Cancel</v-btn>
              <v-btn class="ml-2" @click="submit" color="success">{{ isEditing ? 'Update' : 'Add' }}</v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn class="mb-2" @click="openDialog" color="primary">Add User</v-btn>

    <v-divider></v-divider>
    <h1>Users</h1>
    <v-data-table :headers="tableHeaders" :items="users" item-key="id">
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.email }}</td>
          <td>{{ item.firstName }}</td>
          <td>{{ item.lastName }}</td>
          <td>
            <v-btn @click="editUser(item)" color="primary">Edit</v-btn>
            <v-btn class="ml-2" @click="deleteUser(item.id)" color="error">Delete</v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <h1>Events</h1>
    <v-data-table :headers="tableEventHeaders" :items="events" item-key="id">
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.start }}</td>
          <td>{{ item.end }}</td>
          <td>{{ item.sections }}</td>
          <td>
            <v-btn class="ml-2" @click="deleteEvent(item)" color="error">Delete</v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { db, storage } from '@/plugins/firebase'
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import {deleteObject, ref} from "firebase/storage"


export default {
  data() {
    return {
      events: [],
      message: "",
      dialog: false,
      isEditing: false,
      editedUser: {
        email: "",
        firstName: "",
        lastName: "",
        position: "",
        profile: "",
      },
      profileOptions: ["admin", "organiser", "gallery", "sponsor"],
      tableHeaders: [
        { title: "Email", value: "email" },
        { title: "First Name", value: "firstName" },
        { title: "Last Name", value: "lastName" },
        { title: "Actions", value: "actions", sortable: false },
      ],
      tableEventHeaders: [
        { title: "Event", value: "name" },
        { title: "Start", value: "start" },
        { title: "End", value: "end" },
        { title: "Sections", value: "sections" },
      ],
      users: [],
    };
  },
  methods: {
    submit() {
      if (this.isEditing) {
        this.updateUser()
      } else {
        this.addUser()
      }
    },
    openDialog() {
      this.isEditing = false;
      this.editedUser = {
        email: "",
        firstName: "",
        lastName: "",
        position: "",
        profile: "",
      };
      this.dialog = true;
      this.message = ""
    },
    closeDialog() {
      this.dialog = false;
      this.message = ""
    },
    checkNotExistingEmail(email) {
      const emailCheck = this.users.find((user) => user.email == email)
      return emailCheck ? false : true
    },
    async addUser() {
      if (this.checkNotExistingEmail(this.editedUser.email)) {
        try {
          // Add user details to Firestore
          const newUserDocRef = await addDoc(collection(db, `entities/${this.$store.state.org}/users`), {
            email: this.editedUser.email,
            firstName: this.editedUser.firstName,
            lastName: this.editedUser.lastName,
            admin: true,
          });

          // Update local user list
          this.users.push({
            id: newUserDocRef.id,
            ...this.editedUser,
            admin: true,
          });

          // Close dialog
          this.closeDialog();
        } catch (error) {
          console.error("Error adding user:", error);
        }
      } else {
        this.message = "User already exists"
      }
    },
    async editUser(user) {
      this.isEditing = true;
      this.editedUser = { ...user };
      this.dialog = true;
    },
    async updateUser() {
      console.log('update user')
      try {
        // Update user details in Firestore
        await updateDoc(doc(db, `entities/${this.$store.state.org}/users`, this.editedUser.id), {
          // email: this.editedUser.email,
          firstName: this.editedUser.firstName,
          lastName: this.editedUser.lastName,
        });

        // Update local user list
        const index = this.users.findIndex((user) => user.id === this.editedUser.id);
        this.users.splice(index, 1, this.editedUser);

        // Close dialog
        this.closeDialog();
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
    async loadUsers() {
      try {
        const querySnapshot = await getDocs(collection(db, `entities/${this.$store.state.org}/users`));
        this.users = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("Error loading users:", error);
      }
    },
    async loadEvents() {
      try {
        const querySnapshot = await getDocs(collection(db, `events/${this.$store.state.org}/master`));
        this.events = querySnapshot.docs.map((doc) => {
          const data = doc.data().info;

          const info = { id: doc.id, name: data.eventName, start: data.startDate, end: data.endDate, sections: data.nSections }
          return info

        });
      } catch (error) {
        console.error("Error loading events:", error);
      }
    },
    async deleteUser(userId) {
      try {


        // Delete user from Firestore
        await deleteDoc(doc(db, `entities/${this.$store.state.org}/users`, userId));

        // Update local user list
        this.users = this.users.filter((user) => user.id !== userId);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    async deleteLogos(id, nSections){
      console.log(id, nSections)
      for (let i=0; i < nSections; i++) {
        // Create a reference to the file to delete
        console.log(`logos/${id}/${id}_${i}.png`)
        const desertRef = ref(storage, `logos/${id}/${id}_${i}.png`);

        // Delete the file
        await deleteObject(desertRef)
      }
      
    },
    async deleteEvent(item) {
      const eventId = item.id;
      console.log(eventId)
      try {
        // Delete user from Firestore
        await deleteDoc(doc(db, `events/${this.$store.state.org}/master`, eventId));
        await deleteDoc(doc(db, `slots`, eventId));
        await this.deleteLogos(eventId, item.sections)
        // Update local user list
        this.events = this.events.filter((event) => event.id !== eventId);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
  },
  created() {
    this.loadUsers();
    this.loadEvents();
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
